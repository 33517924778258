import React, { useState, useEffect } from 'react';
import "../components.css"
import bolsilloDoble from "../../imgs/recorte_doble/bolsilloDoble.svg"
import interiorCapucha from "../../imgs/recorte_doble/capuchaDoble.svg"
import cinturaDoble from "../../imgs/recorte_doble/cinturaDoble.svg"
import dorsoDoble from "../../imgs/recorte_doble/dorsoDoble.svg"
import franjaDoble from "../../imgs/recorte_doble/franjaDoble.svg";
import contornoDoble from "../../imgs/recorte_doble/frenteDoble.svg"
import guardaInferiorDoble from "../../imgs/recorte_doble/guardaInferior.svg"
import guardaSuperiorDoble from "../../imgs/recorte_doble/guardaSuperior.svg"
import frenteInferiorDoble from "../../imgs/recorte_doble/inferiorFrenteDoble.svg"
import interiorDoble from "../../imgs/recorte_doble/interiorCampera.svg"
import punosDoble from "../../imgs/recorte_doble/punosDoble.svg"
import frenteSuperiorDoble from "../../imgs/recorte_doble/superiorFrenteDoble.svg"
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/camperaDoble/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/camperaDoble/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/camperaDoble/espaldaSuperior.svg";
import franjaEspalda from "../../imgs/dorsos/camperaDoble/franja.svg";
import guardaInferiorEspalda from "../../imgs/dorsos/camperaDoble/guardaInferior.svg";
import guardaSuperiorEspalda from "../../imgs/dorsos/camperaDoble/guardaSuperior.svg";
import punosEspalda from "../../imgs/dorsos/camperaDoble/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Doble({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaCamperaDoble")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])


    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview11",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview13",
                posicion14: "probandoPreview14",
                posicion15: "probandoPreview15"
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "apliqueCampera4",
                posicion5: "apliqueCampera5",
                posicion6: "probando6",
                posicion7: "bordadoDoble7",
                posicion8: "probando8",
                posicion9: "bordadoDoble9",
                posicion10: "probando10",
                posicion11: "probando11",
                posicion12: "probando12",
                posicion13: "probando13",
                posicion14: "probando14",
                posicion15: "probando15",
            })


    }, [componentPreview])


    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "franjaDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        franja: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaInferiorDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaSuperiorDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaSuperior: diseño.color
                    }
                })
            }
        }
    }, [diseño])


    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }
    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} alt="mano" className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteCamperaDoble" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="Doble" src={frenteSuperiorDoble} id="frenteSuperiorDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="Doble" src={frenteInferiorDoble} id="frenteInferiorDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="Doble" src={bolsilloDoble} id="bolsilloDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="Doble" src={interiorCapucha} id="capuchaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="Doble" src={cinturaDoble} id="cinturaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="Doble" src={interiorDoble} id="interiorDoble" className="asd white" />
                    <img alt="Doble" src={punosDoble} id="punosDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="Doble" src={franjaDoble} id="franjaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="Doble" src={guardaInferiorDoble} id="guardaInferiorDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="Doble" src={guardaSuperiorDoble} id="guardaSuperiorDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="Doble" src={dorsoDoble} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteCamperaDoble")} />
                    <img alt="Doble" src={contornoDoble} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="camperaDoble1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "apliqueCamperaDoble1" : "apliqueCamperaDoble1 displayFixed"} onClick={() => handleOption("camperaDoble1")}><p className={clases && clases.camperaDoble1 ? `${clases.camperaDoble1.color} ${clases.camperaDoble1.fuente} ${clases.camperaDoble1.clase}` : "numeros"}>{valor && valor.camperaDoble1 === "" ? 1 : valor && valor.camperaDoble1}</p></span>
                    <span id="camperaDoble2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando2" : "probando2 displayFixed"} onClick={() => handleOption("camperaDoble2")}><p className={clases && clases.camperaDoble2 ? `${clases.camperaDoble2.color} ${clases.camperaDoble2.fuente} ${clases.camperaDoble2.clase}` : "numeros"}>{valor && valor.camperaDoble2 === "" ? 2 : valor && valor.camperaDoble2}</p></span>
                    <span id="camperaDoble3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando3" : "probando3 displayFixed"} onClick={() => handleOption("camperaDoble3")}><p className={clases && clases.camperaDoble3 ? `${clases.camperaDoble3.color} ${clases.camperaDoble3.fuente} ${clases.camperaDoble3.clase}` : "numeros"}>{valor && valor.camperaDoble3 === "" ? 3 : valor && valor.camperaDoble3}</p></span>
                    {/* BODADOS */}
                    <span id="camperaDoble7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("camperaDoble7")}><p className={clases && clases.camperaDoble7 ? `${clases.camperaDoble7.color} ${clases.camperaDoble7.fuente} ${clases.camperaDoble7.clase}` : "numeros"}>{valor && valor.camperaDoble7 === "" ? 7 : valor && valor.camperaDoble7}</p></span>
                    <span id="camperaDoble8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("camperaDoble8")}><p className={clases && clases.camperaDoble8 ? `${clases.camperaDoble8.color} ${clases.camperaDoble8.fuente} ${clases.camperaDoble8.clase}` : "numeros"}>{valor && valor.camperaDoble8 === "" ? 8 : valor && valor.camperaDoble8}</p></span>
                    <span id="camperaDoble9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("camperaDoble9")}><p className={clases && clases.camperaDoble9 ? `${clases.camperaDoble9.color} ${clases.camperaDoble9.fuente} ${clases.camperaDoble9.clase}` : "numeros"}>{valor && valor.camperaDoble9 === "" ? 9 : valor && valor.camperaDoble9}</p></span>
                    <span id="camperaDoble10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("camperaDoble10")}><p className={clases && clases.camperaDoble10 ? `${clases.camperaDoble10.color} ${clases.camperaDoble10.fuente} ${clases.camperaDoble10.clase}` : "numeros"}>{valor && valor.camperaDoble10 === "" ? 10 : valor && valor.camperaDoble10}</p></span>
                    <span id="camperaDoble12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("camperaDoble12")}><p className={clases && clases.camperaDoble12 ? `${clases.camperaDoble12.color} ${clases.camperaDoble12.fuente} ${clases.camperaDoble12.clase}` : "numeros"}>{valor && valor.camperaDoble12 === "" ? 12 : valor && valor.camperaDoble12}</p></span>
                    <span id="camperaDoble13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} onClick={() => handleOption("camperaDoble13")}><p className={clases && clases.camperaDoble13 ? `${clases.camperaDoble13.color} ${clases.camperaDoble13.fuente} ${clases.camperaDoble13.clase}` : "numeros"}>{valor && valor.camperaDoble13 === "" ? 13 : valor && valor.camperaDoble13}</p></span>

                </div>
                <div id="espaldaCamperaDoble" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="Doble" src={cinturaEspalda} id="cinturaEspaldaCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="Doble" src={espaldaInferior} id="espaldaInferiorCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="Doble" src={espaldaSuperior} id="espaldaSuperiorCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="Doble" src={franjaEspalda} id="franjaEspaldaCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="Doble" src={guardaInferiorEspalda} id="guardaInferiorEspaldaCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="Doble" src={guardaSuperiorEspalda} id="guardaSuperiorEspaldaCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="Doble" src={punosEspalda} id="punosEspaldaCamperaDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="Doble" src={contornoDoble} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaCamperaDoble")} />
                    <img alt="Doble" src={dorsoDoble} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="camperaDoble6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "apliqueCamperaDoble6" : "apliqueCamperaDoble6 displayFixed"} onClick={() => handleOption("camperaDoble6")}><p className={clases && clases.camperaDoble6 ? `${clases.camperaDoble6.color} ${clases.camperaDoble6.fuente} ${clases.camperaDoble6.clase}` : "numeros"}>{valor && valor.camperaDoble6 === "" ? 6 : valor && valor.camperaDoble6}</p></span>
                    {/* BORDADOS */}
                    <span id="camperaDoble14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando14 displayFixed"} onClick={() => handleOption("camperaDoble14")}><p className={clases && clases.camperaDoble14 ? `${clases.camperaDoble14.color} ${clases.camperaDoble14.fuente} ${clases.camperaDoble14.clase}` : "numeros"}>{valor && valor.camperaDoble14 === "" ? 14 : valor && valor.camperaDoble14}</p></span>
                    <span id="camperaDoble15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando15 displayFixed"} onClick={() => handleOption("camperaDoble15")}><p className={clases && clases.camperaDoble15 ? `${clases.camperaDoble15.color} ${clases.camperaDoble15.fuente} ${clases.camperaDoble15.clase}` : "numeros"}>{valor && valor.camperaDoble15 === "" ? 15 : valor && valor.camperaDoble15}</p></span>

                </div>
            </section>
            <div id="modalcampera_doble" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>
        </>
    )
}