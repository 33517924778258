import React, { useState, useEffect } from 'react';
import "../components.css";
import bolsillo from "../../imgs/buso_v/bolsillo.svg";
import capuchaInteriorPng from "../../imgs/buso_v/capucha.svg";
import cinturaPng from "../../imgs/buso_v/cintura.svg";
import interiorPng from "../../imgs/buso_v/interior.svg";
import mangasPng from "../../imgs/buso_v/mangas.svg";
import contornoBuzo from "../../imgs/buso_v/contornoFrente.svg";
import punosPng from "../../imgs/buso_v/punos.svg";
import frenteSuperior from "../../imgs/buso_v/frenteSuperior.svg";
import frenteInferior from "../../imgs/buso_v/inferiorFrnete.svg";
import guardaInferior from "../../imgs/buso_v/guardaInferior.svg";
import dorso from "../../imgs/buso_v/contornoDorso.svg";
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/buzoV/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/buzoV/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/buzoV/espaldaSuperior.svg";
import guardaEspalda from "../../imgs/dorsos/buzoV/guardaEspalda.svg";
import mangasEspalda from "../../imgs/dorsos/buzoV/mangas.svg";
import punosEspalda from "../../imgs/dorsos/buzoV/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Buso_v({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {

    const [prenda, setPrenda] = useState("espaldaBuzoV")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {

        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview13",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview15",
                posicion14: "probandoPreview14",
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "probando4",
                posicion5: "probando5",
                posicion6: "probando6",
                posicion7: "probando7",
                posicion8: "probando8",
                posicion9: "probando9",
                posicion10: "probando10",
                posicion11: "probando13",
                posicion12: "probando12",
                posicion13: "probando15",
                posicion14: "probando14",
            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "mangasBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        mangas: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaInferiorBuzoV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaInferior: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }

    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }

    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteBuzoV" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="buzo" src={frenteSuperior} id="frenteSuperiorBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={frenteInferior} id="frenteInferiorBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={bolsillo} id="bolsilloBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="buzo" src={capuchaInteriorPng} id="capuchaBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="buzo" src={cinturaPng} id="cinturaBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={interiorPng} id="interiorBuzoV" className="asd white" />
                    <img alt="buzo" src={punosPng} id="punosBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={mangasPng} id="mangasBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="buzo" src={guardaInferior} id="guardaInferiorBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={dorso} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteBuzoV")} />
                    <img alt="buzo" src={contornoBuzo} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzo_v1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion1 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v1")}><p className={clases && clases.buzo_v1 ? `${clases.buzo_v1.color} ${clases.buzo_v1.fuente} ${clases.buzo_v1.clase}` : "numeros"}>{valor && valor.buzo_v1 === "" ? 1 : valor && valor.buzo_v1}</p></span>
                    <span id="buzo_v2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v2")}><p className={clases && clases.buzo_v2 ? `${clases.buzo_v2.color} ${clases.buzo_v2.fuente} ${clases.buzo_v2.clase}` : "numeros"}>{valor && valor.buzo_v2 === "" ? 2 : valor && valor.buzo_v2}</p></span>
                    <span id="buzo_v3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v3")}><p className={clases && clases.buzo_v3 ? `${clases.buzo_v3.color} ${clases.buzo_v3.fuente} ${clases.buzo_v3.clase}` : "numeros"}>{valor && valor.buzo_v3 === "" ? 3 : valor && valor.buzo_v3}</p></span>
                    <span id="buzo_v4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion4 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v4")}><p className={clases && clases.buzo_v4 ? `${clases.buzo_v4.color} ${clases.buzo_v4.fuente} ${clases.buzo_v4.clase}` : "numeros"}>{valor && valor.buzo_v4 === "" ? 4 : valor && valor.buzo_v4}</p></span>
                    <span id="buzo_v5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion5 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v5")}><p className={clases && clases.buzo_v5 ? `${clases.buzo_v5.color} ${clases.buzo_v5.fuente} ${clases.buzo_v5.clase}` : "numeros"}>{valor && valor.buzo_v5 === "" ? 5 : valor && valor.buzo_v5}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo_v7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v7")}><p className={clases && clases.buzo_v7 ? `${clases.buzo_v7.color} ${clases.buzo_v7.fuente} ${clases.buzo_v7.clase}` : "numeros"}>{valor && valor.buzo_v7 === "" ? 7 : valor && valor.buzo_v7}</p></span>
                    <span id="buzo_v8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v8")}><p className={clases && clases.buzo_v8 ? `${clases.buzo_v8.color} ${clases.buzo_v8.fuente} ${clases.buzo_v8.clase}` : "numeros"}>{valor && valor.buzo_v8 === "" ? 8 : valor && valor.buzo_v8}</p></span>
                    <span id="buzo_v9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v9")}><p className={clases && clases.buzo_v9 ? `${clases.buzo_v9.color} ${clases.buzo_v9.fuente} ${clases.buzo_v9.clase}` : "numeros"}>{valor && valor.buzo_v9 === "" ? 9 : valor && valor.buzo_v9}</p></span>
                    <span id="buzo_v10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v10")}><p className={clases && clases.buzo_v10 ? `${clases.buzo_v10.color} ${clases.buzo_v10.fuente} ${clases.buzo_v10.clase}` : "numeros"}>{valor && valor.buzo_v10 === "" ? 10 : valor && valor.buzo_v10}</p></span>
                    <span id="buzo_v11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v11")}><p className={clases && clases.buzo_v11 ? `${clases.buzo_v11.color} ${clases.buzo_v11.fuente} ${clases.buzo_v11.clase}` : "numeros"}>{valor && valor.buzo_v11 === "" ? 11 : valor && valor.buzo_v11}</p></span>
                    <span id="buzo_v12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v12")}><p className={clases && clases.buzo_v12 ? `${clases.buzo_v12.color} ${clases.buzo_v12.fuente} ${clases.buzo_v12.clase}` : "numeros"}>{valor && valor.buzo_v12 === "" ? 12 : valor && valor.buzo_v12}</p></span>

                </div>
                <div id="espaldaBuzoV" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="buzo" src={cinturaEspalda} id="cinturaBuzoVEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={espaldaInferior} id="espaldaInferiorBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={espaldaSuperior} id="espaldaSuperiorBuzoV" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={guardaEspalda} id="guardaInferiorBuzoVEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={mangasEspalda} id="mangasBuzoVEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="buzo" src={punosEspalda} id="punosBuzoVEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={contornoBuzo} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaBuzoV")} />
                    <img alt="buzo" src={dorso} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzo_v6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v6")}><p className={clases && clases.buzo_v13 ? `${clases.buzo_v13.color} ${clases.buzo_v13.fuente} ${clases.buzo_v13.clase}` : "numeros"}>{valor && valor.buzo_v6 === "" ? 6 : valor && valor.buzo_v6}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo_v13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v13")}><p className={clases && clases.buzo_v14 ? `${clases.buzo_v14.color} ${clases.buzo_v14.fuente} ${clases.buzo_v14.clase}` : "numeros"}>{valor && valor.buzo_v13 === "" ? 13 : valor && valor.buzo_v13}</p></span>
                    <span id="buzo_v14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando1 displayFixed"} onClick={() => handleOption("buzo_v14")}><p className={clases && clases.buzo_v15 ? `${clases.buzo_v15.color} ${clases.buzo_v15.fuente} ${clases.buzo_v15.clase}` : "numeros"}>{valor && valor.buzo_v14 === "" ? 14 : valor && valor.buzo_v14}</p></span>

                </div>
            </section>
            <div id="modalbuzo_v" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>

        </>
    )
}