import React, { useState, useEffect } from 'react';
import one from "../../imgs/navBar/numero1.svg";
import downArrow from "../../imgs/navBar/downArrow.svg";
import two from "../../imgs/navBar/numero2.svg";
import "./selectorColores.css";

import ModalPreview from "../modalPreview/modalPreview"

export default function SelectorColores({ clases, ayuda, handleColor, valor, auxiliar, setDiseño, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const data = ["#4b4c80", "#6265a3", "#b1b4da", "#b25295", "#fc5cad", "#d26eaa", "#dc80b4", "#e7abcd", "#f9b9b4", "#f9d9e4", "#f6ebda", "#fbcc91", "#ec4750", "#d48432", "#ee5f32", "#e2263b", "#cc526d", "#752142", "#6d2232", "#441f3a", "#0a2333", "#0c283f", "#50649b", "#384e78", "#313bb5", "#859da8", "#767cb4", "#52d5db", "#a4c5da", "#cce7f6", "#d0eae3", "#7fe5e5", "#aeee44", "#b0c480", "#62cc95", "#48997a", "#4a8a99", "#4a7c4b", "#324441", "#c2d19d", "#ab9370", "#efc867", "#ffda40", "#f9eaac", "#ffffff", "#fffff5", "#cecece", "#b9b9b9", "#cec8ba", "#c4c4c4", "#514d46", "#b7b7b7", "#606060", "#070707",]
    const [selection, setSelection] = useState([])

    /* Eljo la seccion a pintar */
    function handleSeccion(seccion) {
        seccion && setDiseño({
            seccion: seccion.value,
        })
    }

    /* UseEffect que maneja segun el componente renderizado la eleccion de secciones a mostrar */
    useEffect(() => {
        if (component === "buzo") {
            setSelection([
                { key: "FRENTE", value: "frenteBuzo" },
                { key: "BOLSILLO", value: "bolsilloBuzo" },
                { key: "INTERIOR CAPUCHA", value: "capuchaBuzo" },
                { key: "CINTURA", value: "cinturaBuzo" },
                { key: "MANGAS", value: "mangasBuzo" },
                { key: "PUÑOS", value: "punosBuzo" },
            ])
        } else if (component === "buzo_simple") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorBuzoSimple" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorBuzoSimple" },
                { key: "BOLSILLO", value: "bolsilloBuzoSimple" },
                { key: "CAPUCHA", value: "capuchaBuzoSimple" },
                { key: "CINTURA", value: "cinturaBuzoSimple" },
                { key: "PUÑOS", value: "punosBuzoSimple" },
                { key: "GUARDA MANGAS", value: "guardaMangasBuzoSimple" }
            ])
        } else if (component === "buzo_doble") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorBuzoDoble" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorBuzoDoble" },
                { key: "BOLSILLO", value: "bolsilloBuzoDoble" },
                { key: "CAPUCHA", value: "capuchaBuzoDoble" },
                { key: "CINTURA", value: "cinturaBuzoDoble" },
                { key: "PUÑOS", value: "punosBuzoDoble" },
                { key: "FRANJA", value: "franjaBuzoDoble" },
                { key: "GUARDA SUPERIOR", value: "gardaSuperiorBuzoDoble" },
                { key: "GUARDA INFERIOR", value: "gardaInferiorBuzoDoble" },
            ])
        } else if (component === "buzo_v") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorBuzoV" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorBuzoV" },
                { key: "BOLSILLO", value: "bolsilloBuzoV" },
                { key: "CAPUCHA", value: "capuchaBuzoV" },
                { key: "CINTURA", value: "cinturaBuzoV" },
                { key: "PUÑOS", value: "punosBuzoV" },
                { key: "MANGAS", value: "mangasBuzoV" },
                { key: "GUARDA INFERIOR", value: "guardaInferiorBuzoV" },
            ])
        } else if (component === "buzo_vDoble") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorBuzoVDoble" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorBuzoVDoble" },
                { key: "BOLSILLO", value: "bolsilloBuzoVDoble" },
                { key: "CAPUCHA", value: "capuchaBuzoVDoble" },
                { key: "CINTURA", value: "cinturaBuzoVDoble" },
                { key: "PUÑOS", value: "punosBuzoVDoble" },
                { key: "FRANJA", value: "franjaBuzoVDoble" },
                { key: "GUARDA INFERIOR", value: "guardaInferiorBuzoVDoble" },
                { key: "GUARDA SUPERIOR", value: "guardaSuperiorBuzoVDoble" },
            ])
        } else if (component === "campera") {
            setSelection([
                { key: "FRENTE", value: "frenteCampera" },
                { key: "BOLSILLO", value: "bolsilloCampera" },
                { key: "INTERIOR CAPUCHA", value: "capuchaCampera" },
                { key: "CINTURA", value: "cinturaCampera" },
                { key: "MANGAS", value: "mangasCampera" },
                { key: "PUÑOS", value: "punosCampera" },
            ])
        } else if (component === "campera_simple") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorSimple" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorSimple" },
                { key: "BOLSILLO", value: "bolsilloSimple" },
                { key: "CAPUCHA", value: "capuchaSimple" },
                { key: "CINTURA", value: "cinturaSimple" },
                { key: "PUÑOS", value: "punosSimple" },
                { key: "FRANJA", value: "franjaSimple" },
            ])
        } else if (component === "campera_doble") {
            setSelection([
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorDoble" },
                { key: "FRENTE INFERIOR", value: "frenteInferiorDoble" },
                { key: "BOLSILLO", value: "bolsilloDoble" },
                { key: "CAPUCHA", value: "capuchaDoble" },
                { key: "CINTURA", value: "cinturaDoble" },
                { key: "PUÑOS", value: "punosDoble" },
                { key: "FRANJA", value: "franjaDoble" },
                { key: "GUARDA INFERIOR", value: "guardaInferiorDoble" },
                { key: "GUARDA SUPERIOR", value: "guardaSuperiorDoble" }
            ])
        } else if (component === "campera_v") {
            setSelection([
                { key: "FRENTE INFERIOR", value: "frenteInferiorV" },
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorV" },
                { key: "BOLSILLO", value: "bolsilloV" },
                { key: "CAPUCHA", value: "capuchaV" },
                { key: "CINTURA", value: "cinturaV" },
                { key: "MANGAS", value: "mangasV" },
                { key: "PUÑOS", value: "punosV" },
                { key: "GUARDA", value: "guardaInferiorV" },
            ])
        } else if (component === "campera_vDoble") {
            setSelection([
                { key: "FRENTE INFERIOR", value: "frenteInferiorDobleV" },
                { key: "FRENTE SUPERIOR", value: "frenteSuperiorDobleV" },
                { key: "BOLSILLO", value: "bolsilloDobleV" },
                { key: "CAPUCHA", value: "capuchaDobleV" },
                { key: "CINTURA", value: "cinturaDobleV" },
                { key: "MANGAS", value: "mangasDobleV" },
                { key: "PUÑOS", value: "punosDobleV" },
                { key: "FRANJA", value: "franjaDobleV" },
                { key: "GUARDA INFERIOR", value: "guardaInferiorDobleV" },
                { key: "GUARDA SUPERIOR", value: "guardaSuperiorDobleV" }
            ])
        }
    }, [component])

    const [habilitado, setHabilitado] = useState(false)
    useEffect(() => {
        Object.keys(clases).length ? setHabilitado(true) : setHabilitado(false)
    }, [clases])

    /* Flip arrow de la lista de las secciones */
    function aaaaa() {
        document.getElementById("lista").classList.toggle("none")
        document.getElementById("arrow").classList.toggle("flipArrow")
    }

    const [modalPreview, SetModalPreview] = useState(false)
    /* Abre el modal del preivew final */
    function openModalPreview() {
        modalPreview === false ? SetModalPreview(true) : SetModalPreview(false)
        componentPreview === false ? SetComponentPreview(true) : SetComponentPreview(false)
    }

    const [componentPreview, SetComponentPreview] = useState(false) /* Estado que se manda al modal preview para saber que componente mostrar */

    return (
        <>
            <span className="titleSelector">
                <img src={one} alt="1" />
                <p>¿Que parte de la prenda queres pintar?</p>
                <img src={downArrow} alt="arrow" id="arrow" className="flipArrow" onClick={() => aaaaa()} />
            </span>
            <ul id="lista" className="none listaSecciones">
                {selection.map((e) => {
                    return (
                        <li key={e.key} onClick={() => handleSeccion(e)} className="">{e.key}</li>
                    )
                })}


            </ul>
            <span className="titleSelector">
                <img src={two} alt="1" />
                <p>Elegi el color.</p>
                <img src={downArrow} alt="arrow" />
            </span>

            <div className="selectores">
                <div className="prueba">
                    {data.map((e) => {
                        return (
                            <div key={e} style={{ "backgroundColor": e }} onClick={() => handleColor(e)}></div>
                        )
                    })}
                </div>
            </div>

            <div className={modalPreview === false ? "display" : "openModalPreview"}>
                <ModalPreview clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} SetModalPreview={SetModalPreview} openModalPreview={openModalPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} />
            </div>
            {habilitado === true ?
                <button className={habilitado === false ? "utimoPaso display" : "utimoPaso"} onClick={() => openModalPreview()}>AVANZAR AL ULTIMO PASO</button>
                :
                <div>
                    <p className="warningUltimoPaso">Elegi tus bordados o apliques para avanzar al ultimo paso!</p>
                    <p className="loQuieroSin" onClick={() => setHabilitado(true)}>¡Lo quiero sin apliques ni bordados!</p>
                </div>
            }


        </>
    );
}