import React, { useState, useEffect } from 'react';
import "../components.css"
import bolsilloCampera from "../../imgs/campera_simple/bolsillo.svg"
import contornoCampera from "../../imgs/campera_simple/contornoFrente.svg"
import dorsoCampera from "../../imgs/campera_simple/contornoEspalda.svg"
import frenteInferior from "../../imgs/campera_simple/frenteInferior.svg"
import frenteSuperior from "../../imgs/campera_simple/frenteSuperior.svg"
import guardaInferiorMangas from "../../imgs/campera_simple/guardaInferiorMangas.svg"
import interior from "../../imgs/campera_simple/interior.svg"
import Capucha from "../../imgs/campera_simple/capucha.svg"
import punosCampera from "../../imgs/campera_simple/punos.svg"
import cintura from "../../imgs/campera_simple/cintura.svg"
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/camperaSimple/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/camperaSimple/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/camperaSimple/espaldaSuperior.svg";
import guardasMangasEspalda from "../../imgs/dorsos/camperaSimple/guardasMangas.svg";
import punosEspalda from "../../imgs/dorsos/camperaSimple/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Campera_simple({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaCamperaSimple")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview11",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview13",
                posicion14: "probandoPreview14",
                posicion15: "probandoPreview15"
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "apliqueCampera4",
                posicion5: "apliqueCampera5",
                posicion6: "probando6",
                posicion7: "probando7",
                posicion8: "probando8",
                posicion9: "probando9",
                posicion10: "probando10",
                posicion11: "probando11",
                posicion12: "probando12",
                posicion13: "probando13",
                posicion14: "probando14",
                posicion15: "probando15",
            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "franjaSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        franja: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }
    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} alt="mano" className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteCamperaSimple" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="campera" src={frenteSuperior} id="frenteSuperiorSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="campera" src={frenteInferior} id="frenteInferiorSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="campera" src={bolsilloCampera} id="bolsilloSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="campera" src={Capucha} id="capuchaSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="campera" src={cintura} id="cinturaSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="campera" src={interior} id="interiorSimple" className="asd white" />
                    <img alt="campera" src={punosCampera} id="punosSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="campera" src={guardaInferiorMangas} id="franjaSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="campera" src={dorsoCampera} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteCamperaSimple")} />
                    <img alt="campera" src={contornoCampera} className="asd contorno " />
                    {/* APLIQUES */}
                    <span id="camperaSimple1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando1" : "probando1 displayFixed"} onClick={() => handleOption("camperaSimple1")}><p className={clases && clases.camperaSimple1 ? `${clases.camperaSimple1.color} ${clases.camperaSimple1.fuente} ${clases.camperaSimple1.clase}` : "numeros"}>{valor && valor.camperaSimple1 === "" ? 1 : valor && valor.camperaSimple1}</p></span>
                    <span id="camperaSimple2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "probando2 displayFixed"} onClick={() => handleOption("camperaSimple2")}><p className={clases && clases.camperaSimple2 ? `${clases.camperaSimple2.color} ${clases.camperaSimple2.fuente} ${clases.camperaSimple2.clase}` : "numeros"}>{valor && valor.camperaSimple2 === "" ? 2 : valor && valor.camperaSimple2}</p></span>
                    <span id="camperaSimple3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "probando3 displayFixed"} onClick={() => handleOption("camperaSimple3")}><p className={clases && clases.camperaSimple3 ? `${clases.camperaSimple3.color} ${clases.camperaSimple3.fuente} ${clases.camperaSimple3.clase}` : "numeros"}>{valor && valor.camperaSimple3 === "" ? 3 : valor && valor.camperaSimple3}</p></span>
                    <span id="camperaSimple4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "apliqueCampera4" : "apliqueCampera4 displayFixed"} onClick={() => handleOption("camperaSimple4")}><p className={clases && clases.camperaSimple4 ? `${clases.camperaSimple4.color} ${clases.camperaSimple4.fuente} ${clases.camperaSimple4.clase}` : "numeros"}>{valor && valor.camperaSimple4 === "" ? 4 : valor && valor.camperaSimple4}</p></span>
                    <span id="camperaSimple5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "apliqueCampera5" : "apliqueCampera5 displayFixed"} onClick={() => handleOption("camperaSimple5")}><p className={clases && clases.camperaSimple5 ? `${clases.camperaSimple5.color} ${clases.camperaSimple5.fuente} ${clases.camperaSimple5.clase}` : "numeros"}>{valor && valor.camperaSimple5 === "" ? 5 : valor && valor.camperaSimple5}</p></span>
                    {/* BORDADOS */}
                    <span id="camperaSimple7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("camperaSimple7")}><p className={clases && clases.camperaSimple7 ? `${clases.camperaSimple7.color} ${clases.camperaSimple7.fuente} ${clases.camperaSimple7.clase}` : "numeros"}>{valor && valor.camperaSimple7 === "" ? 7 : valor && valor.camperaSimple7}</p></span>
                    <span id="camperaSimple8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("camperaSimple8")}><p className={clases && clases.camperaSimple8 ? `${clases.camperaSimple8.color} ${clases.camperaSimple8.fuente} ${clases.camperaSimple8.clase}` : "numeros"}>{valor && valor.camperaSimple8 === "" ? 8 : valor && valor.camperaSimple8}</p></span>
                    <span id="camperaSimple9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("camperaSimple9")}><p className={clases && clases.camperaSimple9 ? `${clases.camperaSimple9.color} ${clases.camperaSimple9.fuente} ${clases.camperaSimple9.clase}` : "numeros"}>{valor && valor.camperaSimple9 === "" ? 9 : valor && valor.camperaSimple9}</p></span>
                    <span id="camperaSimple10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("camperaSimple10")}><p className={clases && clases.camperaSimple10 ? `${clases.camperaSimple10.color} ${clases.camperaSimple10.fuente} ${clases.camperaSimple10.clase}` : "numeros"}>{valor && valor.camperaSimple10 === "" ? 10 : valor && valor.camperaSimple10}</p></span>
                    <span id="camperaSimple12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("camperaSimple12")}><p className={clases && clases.camperaSimple11 ? `${clases.camperaSimple11.color} ${clases.camperaSimple11.fuente} ${clases.camperaSimple11.clase}` : "numeros"}>{valor && valor.camperaSimple12 === "" ? 12 : valor && valor.camperaSimple12}</p></span>
                    <span id="camperaSimple13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} onClick={() => handleOption("camperaSimple13")}><p className={clases && clases.camperaSimple12 ? `${clases.camperaSimple12.color} ${clases.camperaSimple12.fuente} ${clases.camperaSimple12.clase}` : "numeros"}>{valor && valor.camperaSimple13 === "" ? 13 : valor && valor.camperaSimple13}</p></span>
                </div>
                <div id="espaldaCamperaSimple" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="campera" src={cinturaEspalda} id="cinturaSimpleEspalda" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="campera" src={espaldaInferior} id="espaldaInferiorSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="campera" src={espaldaSuperior} id="espaldaSuperiorSimple" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="campera" src={guardasMangasEspalda} id="guardasMangasEspalda" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="campera" src={punosEspalda} id="punosSimpleEspalda" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="campera" src={contornoCampera} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaCamperaSimple")} />
                    <img alt="campera" src={dorsoCampera} className="asd contorno " />
                    {/* APLIQUES */}
                    <span id="camperaSimple6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "probando6 displayFixed"} onClick={() => handleOption("camperaSimple6")}><p className={clases && clases.camperaSimple6 ? `${clases.camperaSimple6.color} ${clases.camperaSimple6.fuente} ${clases.camperaSimple6.clase}` : "numeros"}>{valor && valor.camperaSimple6 === "" ? 6 : valor && valor.camperaSimple6}</p></span>
                    {/* BORDADOS */}
                    <span id="camperaSimple14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando14 displayFixed"} onClick={() => handleOption("camperaSimple14")}><p className={clases && clases.camperaSimple14 ? `${clases.camperaSimple14.color} ${clases.camperaSimple14.fuente} ${clases.camperaSimple14.clase}` : "numeros"}>{valor && valor.camperaSimple14 === "" ? 14 : valor && valor.camperaSimple14}</p></span>
                    <span id="camperaSimple15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando15 displayFixed"} onClick={() => handleOption("camperaSimple15")}><p className={clases && clases.camperaSimple15 ? `${clases.camperaSimple15.color} ${clases.camperaSimple15.fuente} ${clases.camperaSimple15.clase}` : "numeros"}>{valor && valor.camperaSimple15 === "" ? 15 : valor && valor.camperaSimple15}</p></span>

                </div>
            </section>
            <div id="modalcampera_simple" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>
        </>
    )
}