import React, { useState, useEffect } from 'react';
import "../components.css";
import bolsillo from "../../imgs/buso_doble/bolsillo.svg";
import capuchaInteriorPng from "../../imgs/buso_doble/capucha.svg";
import cinturaPng from "../../imgs/buso_doble/cintura.svg";
import interiorPng from "../../imgs/buso_doble/interiorCampera.svg";
import contornoBuzo from "../../imgs/buso_doble/contornoFrente.svg";
import punosPng from "../../imgs/buso_doble/punos.svg";
import frenteInferior from "../../imgs/buso_doble/inferiorFrente_mangas.svg";
import frenteSuperior from "../../imgs/buso_doble/superiorFrente_mangas.svg";
import dorso from "../../imgs/buso_doble/contornoDorso.svg";
import franja from "../../imgs/buso_doble/franja.svg";
import guardaInferior from "../../imgs/buso_doble/guardaInferior.svg";
import guardaSuperior from "../../imgs/buso_doble/guardaSuperior.svg";

/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/buzoDoble/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/buzoDoble/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/buzoDoble/espaldaSuperior.svg";
import franjaEspalda from "../../imgs/dorsos/buzoDoble/franjaEspalda.svg";
import guardaInferiorEsplada from "../../imgs/dorsos/buzoDoble/guardaInferiorEspalda.svg";
import guardasuperiorEspalda from "../../imgs/dorsos/buzoDoble/guardaSuperior.svg";
import punosEspalda from "../../imgs/dorsos/buzoDoble/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Buzo_doble({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaBuzoDoble")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")

    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {

        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "apliqueBuzoDoblePreview1",
                posicion2: "apliqueBuzoDoblePreview2",
                posicion3: "apliqueBuzoDoblePreview3",
                posicion4: "apliqueBuzoDoblePreview4",
                posicion5: "apliqueBuzoDoblePreview5",
                posicion6: "apliqueBuzoDoblePreview6",
                posicion7: "probandoPreview8",
                posicion8: "probandoPreview10",
                posicion9: "probandoPreview12",
                posicion10: "probandoPreview13",
                posicion11: "probandoPreview14",
                posicion12: "probandoPreview15",

            })
            :
            SetPosicionesApliques({
                posicion1: "apliqueBuzoDoble1",
                posicion2: "apliqueBuzoDoble2",
                posicion3: "apliqueBuzoDoble3",
                posicion4: "apliqueBuzoDoble4",
                posicion5: "apliqueBuzoDoble5",
                posicion6: "apliqueBuzoDoble6",
                posicion7: "apliqueBuzoDoble7",
                posicion8: "apliqueBuzoDoble8",
                posicion9: "apliqueBuzoDoble9",
                posicion10: "apliqueBuzoDoble10",
                posicion11: "apliqueBuzoDoble11",
                posicion12: "apliqueBuzoDoble12",

            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "franjaBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        franja: diseño.color
                    }
                })
            } else if (diseño.seccion === "gardaSuperiorBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "gardaInferiorBuzoDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaInferior: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }
    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />
                <div id="frenteBuzoDoble" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="buzo" src={frenteSuperior} id="frenteSuperiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={frenteInferior} id="frenteInferiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={bolsillo} id="bolsilloBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="buzo" src={capuchaInteriorPng} id="capuchaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="buzo" src={cinturaPng} id="cinturaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={interiorPng} id="interiorBuzoDoble" className="asd white" />
                    <img alt="buzo" src={punosPng} id="punosBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={franja} id="franjaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="buzo" src={guardaSuperior} id="gardaSuperiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="buzo" src={guardaInferior} id="gardaInferiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={dorso} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteBuzoDoble")} />
                    <img alt="buzo" src={contornoBuzo} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzoDoble1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion1 : "displayFixed"} onClick={() => handleOption("buzoDoble1")}><p className={clases && clases.buzoDoble1 ? `${clases.buzoDoble1.color} ${clases.buzoDoble1.fuente} ${clases.buzoDoble1.clase}` : "numeros"}>{valor && valor.buzoDoble1 === "" ? 1 : valor && valor.buzoDoble1}</p></span>
                    <span id="buzoDoble2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "displayFixed"} onClick={() => handleOption("buzoDoble2")}><p className={clases && clases.buzoDoble2 ? `${clases.buzoDoble2.color} ${clases.buzoDoble2.fuente} ${clases.buzoDoble2.clase}` : "numeros"}>{valor && valor.buzoDoble2 === "" ? 2 : valor && valor.buzoDoble2}</p></span>
                    <span id="buzoDoble3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "displayFixed"} onClick={() => handleOption("buzoDoble3")}><p className={clases && clases.buzoDoble3 ? `${clases.buzoDoble3.color} ${clases.buzoDoble3.fuente} ${clases.buzoDoble3.clase}` : "numeros"}>{valor && valor.buzoDoble3 === "" ? 3 : valor && valor.buzoDoble3}</p></span>
                    <span id="buzoDoble4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion4 : "displayFixed"} onClick={() => handleOption("buzoDoble4")}><p className={clases && clases.buzoDoble4 ? `${clases.buzoDoble4.color} ${clases.buzoDoble4.fuente} ${clases.buzoDoble4.clase}` : "numeros"}>{valor && valor.buzoDoble4 === "" ? 4 : valor && valor.buzoDoble4}</p></span>
                    <span id="buzoDoble5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion5 : "displayFixed"} onClick={() => handleOption("buzoDoble5")}><p className={clases && clases.buzoDoble5 ? `${clases.buzoDoble5.color} ${clases.buzoDoble5.fuente} ${clases.buzoDoble5.clase}` : "numeros"}>{valor && valor.buzoDoble5 === "" ? 5 : valor && valor.buzoDoble5}</p></span>
                    {/* BORDADOS */}
                    <span id="buzoDoble7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("buzoDoble7")}><p className={clases && clases.buzoDoble7 ? `${clases.buzoDoble7.color} ${clases.buzoDoble7.fuente} ${clases.buzoDoble7.clase}` : "numeros"}>{valor && valor.buzoDoble7 === "" ? 7 : valor && valor.buzoDoble7}</p></span>
                    <span id="buzoDoble8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("buzoDoble8")}><p className={clases && clases.buzoDoble8 ? `${clases.buzoDoble8.color} ${clases.buzoDoble8.fuente} ${clases.buzoDoble8.clase}` : "numeros"}>{valor && valor.buzoDoble8 === "" ? 8 : valor && valor.buzoDoble8}</p></span>
                    <span id="buzoDoble9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("buzoDoble9")}><p className={clases && clases.buzoDoble9 ? `${clases.buzoDoble9.color} ${clases.buzoDoble9.fuente} ${clases.buzoDoble9.clase}` : "numeros"}>{valor && valor.buzoDoble9 === "" ? 9 : valor && valor.buzoDoble9}</p></span>
                    <span id="buzoDoble10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("buzoDoble10")}><p className={clases && clases.buzoDoble10 ? `${clases.buzoDoble10.color} ${clases.buzoDoble10.fuente} ${clases.buzoDoble10.clase}` : "numeros"}>{valor && valor.buzoDoble10 === "" ? 10 : valor && valor.buzoDoble10}</p></span>

                </div>
                <div id="espaldaBuzoDoble" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="buzo" src={contornoBuzo} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaBuzoDoble")} />
                    <img alt="buzo" src={cinturaEspalda} id="cinturaEspaldaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={espaldaInferior} id="espaldaInferiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={espaldaSuperior} id="espaldaSuperiorBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={franjaEspalda} id="franjaEspaldaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="buzo" src={guardaInferiorEsplada} id="guardaInferiorEspaldaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={guardasuperiorEspalda} id="guardaSuperiorEspaldaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="buzo" src={punosEspalda} id="punosEspaldaBuzoDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={dorso} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzoDoble6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "displayFixed"} onClick={() => handleOption("buzoDoble6")}><p className={clases && clases.buzoDoble6 ? `${clases.buzoDoble6.color} ${clases.buzoDoble6.fuente} ${clases.buzoDoble6.clase}` : "numeros"}>{valor && valor.buzoDoble6 === "" ? 6 : valor && valor.buzoDoble6}</p></span>
                    {/* BORDADOS */}
                    <span id="buzoDoble11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "displayFixed"} onClick={() => handleOption("buzoDoble11")}><p className={clases && clases.buzoDoble11 ? `${clases.buzoDoble11.color} ${clases.buzoDoble11.fuente} ${clases.buzoDoble11.clase}` : "numeros"}>{valor && valor.buzoDoble11 === "" ? 11 : valor && valor.buzoDoble11}</p></span>
                    <span id="buzoDoble12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("buzoDoble12")}><p className={clases && clases.buzoDoble12 ? `${clases.buzoDoble12.color} ${clases.buzoDoble12.fuente} ${clases.buzoDoble12.clase}` : "numeros"}>{valor && valor.buzoDoble12 === "" ? 12 : valor && valor.buzoDoble12}</p></span>

                </div>
            </section>
            <div id="modalbuzo_doble" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} valor={valor} setValor={setValor} /></div>

        </>
    )
}