import React, { useState, useEffect } from 'react';
import "../components.css";
import bolsillo from "../../imgs/buzo-fragmentado/modelo1-bolsillo.svg";
import capuchaInteriorPng from "../../imgs/buzo-fragmentado/modelo1-capucha-interior.svg";
import cinturaPng from "../../imgs/buzo-fragmentado/modelo1-cintura.svg";
import interiorPng from "../../imgs/buzo-fragmentado/modelo1-interior.svg";
import mangasPng from "../../imgs/buzo-fragmentado/modelo1-mangas.svg";
import contornoBuzo from "../../imgs/buzo-fragmentado/contornoBuzo.svg";
import punosPng from "../../imgs/buzo-fragmentado/modelo1-punos.svg";
import frente from "../../imgs/buzo-fragmentado/frente.svg";
import dorso from "../../imgs/buzo-fragmentado/buzo1_dorso.svg";

/* ESPALDA */
import espladaCapucha from "../../imgs/dorsos/buzo/espaldaCapucha.svg";
import cinturaEsplada from "../../imgs/dorsos/buzo/cintura.svg";
import mangasEspalda from "../../imgs/dorsos/buzo/mangas.svg";
import punosEspalda from "../../imgs/dorsos/buzo/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Buzo({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espalda")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])


    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])


    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview11",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview13",
                posicion14: "probandoPreview14",
                posicion15: "probandoPreview15"
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "probando4",
                posicion5: "probando5",
                posicion6: "probando6",
                posicion7: "probando7",
                posicion8: "probando8",
                posicion9: "probando9",
                posicion10: "probando10",
                posicion11: "probando11",
                posicion12: "probando12",
                posicion13: "probando13",
                posicion14: "probando14",
                posicion15: "probando15"
            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frente: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "mangasBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        mangas: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosBuzo") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        puños: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }

    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }



    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frente" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="buzo" src={dorso} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frente")} />
                    <img alt="buzo" src={capuchaInteriorPng} className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="buzo" src={interiorPng} className="asd white" />
                    <img alt="buzo" src={mangasPng} className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="buzo" src={bolsillo} className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="buzo" src={punosPng} className={`asd white ${auxiliarComponent && auxiliarComponent.puños}`} />
                    <img alt="buzo" src={cinturaPng} className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={contornoBuzo} className="asd contorno" />
                    <img alt="buzo" src={frente} className={`asd white ${auxiliarComponent && auxiliarComponent.frente}`} />
                    {/* APLIQUES */}
                    <span id="buzo1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" ? posicionesApliques.posicion1 : "displayFixed"} ><p onClick={() => handleOption("buzo1")} className={clases && clases.buzo1 && !componentPreview ? `${clases.buzo1.color} ${clases.buzo1.fuente} ${clases.buzo1.clase}` : "numeros"}>{valor && valor.buzo1 === "" || componentPreview === true ? 1 : valor && valor.buzo1}</p></span>
                    <span id="buzo2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" ? posicionesApliques.posicion2 : "displayFixed"} ><p onClick={() => handleOption("buzo2")} className={clases && clases.buzo2 && !componentPreview ? `${clases.buzo2.color} ${clases.buzo2.fuente} ${clases.buzo2.clase}` : "numeros"}>{valor && valor.buzo2 === "" || componentPreview === true ? 2 : valor && valor.buzo2}</p></span>
                    <span id="buzo3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" ? posicionesApliques.posicion3 : "displayFixed"} ><p onClick={() => handleOption("buzo3")} className={clases && clases.buzo3 && !componentPreview ? `${clases.buzo3.color} ${clases.buzo3.fuente} ${clases.buzo3.clase}` : "numeros"}>{valor && valor.buzo3 === "" || componentPreview === true ? 3 : valor && valor.buzo3}</p></span>
                    <span id="buzo4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" ? posicionesApliques.posicion4 : "displayFixed"} ><p onClick={() => handleOption("buzo4")} className={clases && clases.buzo4 && !componentPreview ? `${clases.buzo4.color} ${clases.buzo4.fuente} ${clases.buzo4.clase}` : "numeros"}>{valor && valor.buzo4 === "" || componentPreview === true ? 4 : valor && valor.buzo4}</p></span>
                    <span id="buzo5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" ? posicionesApliques.posicion5 : "displayFixed"} ><p onClick={() => handleOption("buzo5")} className={clases && clases.buzo5 && !componentPreview ? `${clases.buzo5.color} ${clases.buzo5.fuente} ${clases.buzo5.clase}` : "numeros"}>{valor && valor.buzo5 === "" || componentPreview === true ? 5 : valor && valor.buzo5}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} ><p onClick={() => handleOption("buzo7")} className={clases && clases.buzo7 && !componentPreview ? `${clases.buzo7.color} ${clases.buzo7.fuente} ${clases.buzo7.clase}` : "numeros"}>{valor && valor.buzo7 === "" || componentPreview === true ? 7 : valor && valor.buzo7}</p></span>
                    <span id="buzo8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} ><p onClick={() => handleOption("buzo8")} className={clases && clases.buzo8 && !componentPreview ? `${clases.buzo8.color} ${clases.buzo8.fuente} ${clases.buzo8.clase}` : "numeros"}>{valor && valor.buzo8 === "" || componentPreview === true ? 8 : valor && valor.buzo8}</p></span>
                    <span id="buzo9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} ><p onClick={() => handleOption("buzo9")} className={clases && clases.buzo9 && !componentPreview ? `${clases.buzo9.color} ${clases.buzo9.fuente} ${clases.buzo9.clase}` : "numeros"}>{valor && valor.buzo9 === "" || componentPreview === true ? 9 : valor && valor.buzo9}</p></span>
                    <span id="buzo10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} ><p onClick={() => handleOption("buzo10")} className={clases && clases.buzo10 && !componentPreview ? `${clases.buzo10.color} ${clases.buzo10.fuente} ${clases.buzo10.clase}` : "numeros"}>{valor && valor.buzo10 === "" || componentPreview === true ? 10 : valor && valor.buzo10}</p></span>
                    <span id="buzo11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "displayFixed"} ><p onClick={() => handleOption("buzo11")} className={clases && clases.buzo11 && !componentPreview ? `${clases.buzo11.color} ${clases.buzo11.fuente} ${clases.buzo11.clase}` : "numeros"}>{valor && valor.buzo11 === "" || componentPreview === true ? 11 : valor && valor.buzo11}</p></span>
                    <span id="buzo12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} ><p onClick={() => handleOption("buzo12")} className={clases && clases.buzo12 && !componentPreview ? `${clases.buzo12.color} ${clases.buzo12.fuente} ${clases.buzo12.clase}` : "numeros"}>{valor && valor.buzo12 === "" || componentPreview === true ? 12 : valor && valor.buzo12}</p></span>
                    <span id="buzo13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} ><p onClick={() => handleOption("buzo13")} className={clases && clases.buzo13 && !componentPreview ? `${clases.buzo13.color} ${clases.buzo13.fuente} ${clases.buzo13.clase}` : "numeros"}>{valor && valor.buzo13 === "" || componentPreview === true ? 13 : valor && valor.buzo13}</p></span>

                </div>
                <div id="espalda" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="buzo" src={contornoBuzo} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espalda")} />
                    <img alt="buzo" src={espladaCapucha} className={`asd white ${auxiliarComponent && auxiliarComponent.frente}`} />
                    <img alt="buzo" src={cinturaEsplada} className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={mangasEspalda} className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="buzo" src={punosEspalda} className={`asd white ${auxiliarComponent && auxiliarComponent.puños}`} />
                    <img alt="buzo" src={dorso} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzo6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "probando6 displayFixed"} onClick={() => handleOption("buzo6")}><p className={clases && clases.buzo6 && !componentPreview ? `${clases.buzo6.color} ${clases.buzo6.fuente} ${clases.buzo6.clase}` : "numeros"}>{valor && valor.buzo6 === "" || componentPreview === true ? 6 : valor && valor.buzo6}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando14 displayFixed"} onClick={() => handleOption("buzo14")}><p className={clases && clases.buzo14 && !componentPreview ? `${clases.buzo14.color} ${clases.buzo14.fuente} ${clases.buzo14.clase}` : "numeros"}>{valor && valor.buzo14 === "" || componentPreview === true ? 14 : valor && valor.buzo14}</p></span>
                    <span id="buzo15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando15 displayFixed"} onClick={() => handleOption("buzo15")}><p className={clases && clases.buzo15 && !componentPreview ? `${clases.buzo15.color} ${clases.buzo15.fuente} ${clases.buzo15.clase}` : "numeros"}>{valor && valor.buzo15 === "" || componentPreview === true ? 15 : valor && valor.buzo15}</p></span>


                </div>
            </section >
            <div id="modalbuzo" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>
        </>
    )
}