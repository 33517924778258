import React, { useState, useEffect } from "react";
import "./modal.css"
import close from "../../imgs/ei_close.svg";


export default function Modal({ SetAyuda, SetClases, component, option, setValor }) {
    const data = ["#4b4c80", "#6265a3", "#b1b4da", "#b25295", "#fc5cad", "#d26eaa", "#dc80b4", "#e7abcd", "#f9b9b4", "#f9d9e4", "#f6ebda", "#fbcc91", "#ec4750", "#d48432", "#ee5f32", "#e2263b", "#cc526d", "#752142", "#6d2232", "#441f3a", "#0a2333", "#0c283f", "#50649b", "#384e78", "#313bb5", "#859da8", "#767cb4", "#52d5db", "#a4c5da", "#cce7f6", "#d0eae3", "#7fe5e5", "#aeee44", "#b0c480", "#62cc95", "#48997a", "#4a8a99", "#4a7c4b", "#324441", "#c2d19d", "#ab9370", "#efc867", "#ffda40", "#f9eaac", "#ffffff", "#fffff5", "#cecece", "#b9b9b9", "#cec8ba", "#c4c4c4", "#514d46", "#b7b7b7", "#606060", "#070707",]

    const [auxTypeFont, setTypeFont] = useState("")
    const [valueFontInput, setValueFontInput] = useState("")

    /* Cerrar Modal */
    function hadleCloseModal() {
        setValueFontInput("")
        document.getElementById(`modal${component}`).classList.add("open")
    }
    /* Manejo los valores del input para cada componente */
    function handelChangeFont(value) {
        setValueFontInput(value)
        if (component === "buzo") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "buzo_doble") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "buzo_simple") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "buzo_v") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "buzo_vDoble") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "campera") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "campera_simple") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "campera_doble") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "campera_v") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        } else if (component === "campera_vDoble") {
            setValor((preOptions) => {
                return {
                    ...preOptions,
                    [option]: value,
                }
            })
        }
    }
    /* Añado la fuente seleccionada*/
    function handleTypeFont(value) {
        SetClases((preValues) => {
            return {
                ...preValues, [option]: { fuente: value }
            }
        })
        SetAyuda((preValor) => {
            return {
                ...preValor + 1
            }
        })
    }

    function handleColorFont(value) {
        SetClases((preValues) => {
            return {
                ...preValues, [option]: { ...preValues[option], color: value }
            }
        })
        SetAyuda((preValor) => {
            return {
                ...preValor + 1
            }
        })
    }

    return (
        <>
            <div className="modal">
                <img alt="close" src={close} id="close" onClick={() => hadleCloseModal()} />
                <p>Escribí la palabra y seleccioná la tipografía que más te guste. Luego, como último paso, podrás seleccionar el color.</p>

                <input value={valueFontInput} id={`inputModal${component}`} type="text" placeholder="¿Que queres poner?" onChange={(event) => handelChangeFont(event.target.value)} />

                <select id="select" name="select" onChange={(event) => handleTypeFont(event.target.value)}>
                    <option value="">Seleccione su tipo de fuente</option>
                    <option value="anton">Anton</option>
                    <option value="xanh" >Xanh Mono</option>
                    <option value="bungee" >Bungee</option>
                    <option value="cookie" >Cookie</option>
                </select>
                <button onClick={() => hadleCloseModal()} >APLICAR TEXTO</button>

                <div className="selectoresModal">
                    <div className="pruebaModal">
                        {data.map((e) => {
                            return (
                                <div key={e} style={{ "backgroundColor": e }} onClick={() => handleColorFont("color" + e.slice(1))}></div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}