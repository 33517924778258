import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./components/home/home"
/* BUZO */
import Buzo from "./components/buzo/buzo"
import Buzo_doble from "../src/components/buso_doble/buso_doble"
import Buso_v from "../src/components/buso_v/buso_v"
import Buso_vDoble from "../src/components/buso_vDoble/buso_vDoble"
import Buso_simple from "../src/components/buso_simple/buso_simple"
/* CAMPERA */
import Campera from "./components/campera/campera"
import Doble from "./components/campera_doble/campera_doble"
import DobleV from "./components/campera_vDoble/campera_vDoble"
import Campera_simple from "./components/campera_simple/campera_simple"
import Campera_v from "./components/campera_v/campera_v"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* BUZO */}
        <Route path="/buzo" element={<Buzo />} />
        <Route path="/buzo_doble" element={<Buzo_doble />} />
        <Route path="/Buso_v" element={<Buso_v />} />
        <Route path="/Buso_vDoble" element={<Buso_vDoble />} />
        <Route path="/Buso_simple" element={<Buso_simple />} />
        {/* CAMPERA */}
        <Route path="/campera" element={<Campera />} />
        <Route path="/doble" element={<Doble />} />
        <Route path="/doblev" element={<DobleV />} />
        <Route path="/Campera_simple" element={<Campera_simple />} />
        <Route path="/Campera_v" element={<Campera_v />} />
      </Routes>
    </div>
  );
}

export default App;
