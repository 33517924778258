import React, { useState, useEffect } from 'react';
import "../components.css";
import bolsillo from "../../imgs/buso_simple/bolsillos.svg";
import capucha from "../../imgs/buso_simple/capucha.svg";
import cintura from "../../imgs/buso_simple/cintura.svg";
import interior from "../../imgs/buso_simple/interior.svg";
import contorno from "../../imgs/buso_simple/contornoFrente.svg";
import punos from "../../imgs/buso_simple/punos.svg";
import frenteSuperior from "../../imgs/buso_simple/frente_mangasSuperior.svg";
import frenteInferior from "../../imgs/buso_simple/frente_mangasInferior.svg";
import dorso from "../../imgs/buso_simple/dorsoSimple.svg";
import guardaMangas from "../../imgs/buso_simple/guardaaMangas.svg";
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/buzoSimple/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/buzoSimple/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/buzoSimple/espaldaSuperior.svg";
import guardaMangasEspalda from "../../imgs/dorsos/buzoSimple/guardaMangas.svg";
import punosEspalda from "../../imgs/dorsos/buzoSimple/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Buso_simple({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaBuzoSimple")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview11",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview13",
                posicion14: "probandoPreview14",
                posicion15: "probandoPreview15",
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "probando4",
                posicion5: "probando5",
                posicion6: "probando6",
                posicion7: "probando7",
                posicion8: "probando8",
                posicion9: "probando9",
                posicion10: "probando10",
                posicion11: "probando11BSimple",
                posicion12: "probando12",
                posicion13: "probando13",
                posicion14: "probando14",
                posicion15: "probando15",
            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaMangasBuzoSimple") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaMangas: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }

    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }

    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteBuzoSimple" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="buzo" src={frenteSuperior} id="frenteSuperiorBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={frenteInferior} id="frenteInferiorBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={bolsillo} id="bolsilloBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="buzo" src={capucha} id="capuchaBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="buzo" src={cintura} id="cinturaBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={interior} id="interiorBuzoSimple" className="asd white" />
                    <img alt="buzo" src={punos} id="punosBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={guardaMangas} id="guardaMangasBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaMangas}`} />
                    <img alt="buzo" src={dorso} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteBuzoSimple")} />
                    <img alt="buzo" src={contorno} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzoSimple1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion1 : "displayFixed"} onClick={() => handleOption("buzoSimple1")}><p className={clases && clases.buzoSimple1 && !componentPreview ? `${clases.buzoSimple1.color} ${clases.buzoSimple1.fuente} ${clases.buzoSimple1.clase}` : "numeros"}>{valor && valor.buzoSimple1 === "" || componentPreview === true ? 1 : valor && valor.buzoSimple1}</p></span>
                    <span id="buzoSimple2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "displayFixed"} onClick={() => handleOption("buzoSimple2")}><p className={clases && clases.buzoSimple2 && !componentPreview ? `${clases.buzoSimple2.color} ${clases.buzoSimple2.fuente} ${clases.buzoSimple2.clase}` : "numeros"}>{valor && valor.buzoSimple2 === "" || componentPreview === true ? 2 : valor && valor.buzoSimple2}</p></span>
                    <span id="buzoSimple3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "displayFixed"} onClick={() => handleOption("buzoSimple3")}><p className={clases && clases.buzoSimple3 && !componentPreview ? `${clases.buzoSimple3.color} ${clases.buzoSimple3.fuente} ${clases.buzoSimple3.clase}` : "numeros"}>{valor && valor.buzoSimple3 === "" || componentPreview === true ? 3 : valor && valor.buzoSimple3}</p></span>
                    <span id="buzoSimple4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion4 : "displayFixed"} onClick={() => handleOption("buzoSimple4")}><p className={clases && clases.buzoSimple4 && !componentPreview ? `${clases.buzoSimple4.color} ${clases.buzoSimple4.fuente} ${clases.buzoSimple4.clase}` : "numeros"}>{valor && valor.buzoSimple4 === "" || componentPreview === true ? 4 : valor && valor.buzoSimple4}</p></span>
                    <span id="buzoSimple5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion5 : "displayFixed"} onClick={() => handleOption("buzoSimple5")}><p className={clases && clases.buzoSimple5 && !componentPreview ? `${clases.buzoSimple5.color} ${clases.buzoSimple5.fuente} ${clases.buzoSimple5.clase}` : "numeros"}>{valor && valor.buzoSimple5 === "" || componentPreview === true ? 5 : valor && valor.buzoSimple5}</p></span>
                    {/* BORDADOS */}
                    <span id="buzoSimple7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("buzoSimple7")}><p className={clases && clases.buzoSimple7 && !componentPreview ? `${clases.buzoSimple7.color} ${clases.buzoSimple7.fuente} ${clases.buzoSimple7.clase}` : "numeros"}>{valor && valor.buzoSimple7 === "" || componentPreview === true ? 7 : valor && valor.buzoSimple7}</p></span>
                    <span id="buzoSimple8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("buzoSimple8")}><p className={clases && clases.buzoSimple8 && !componentPreview ? `${clases.buzoSimple8.color} ${clases.buzoSimple8.fuente} ${clases.buzoSimple8.clase}` : "numeros"}>{valor && valor.buzoSimple8 === "" || componentPreview === true ? 8 : valor && valor.buzoSimple8}</p></span>
                    <span id="buzoSimple9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("buzoSimple9")}><p className={clases && clases.buzoSimple9 && !componentPreview ? `${clases.buzoSimple9.color} ${clases.buzoSimple9.fuente} ${clases.buzoSimple9.clase}` : "numeros"}>{valor && valor.buzoSimple9 === "" || componentPreview === true ? 9 : valor && valor.buzoSimple9}</p></span>
                    <span id="buzoSimple10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("buzoSimple10")}><p className={clases && clases.buzoSimple10 && !componentPreview ? `${clases.buzoSimple10.color} ${clases.buzoSimple10.fuente} ${clases.buzoSimple10.clase}` : "numeros"}>{valor && valor.buzoSimple10 === "" || componentPreview === true ? 10 : valor && valor.buzoSimple10}</p></span>
                    <span id="buzoSimple11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "displayFixed"} onClick={() => handleOption("buzoSimple11")}><p className={clases && clases.buzoSimple11 && !componentPreview ? `${clases.buzoSimple11.color} ${clases.buzoSimple11.fuente} ${clases.buzoSimple11.clase}` : "numeros"}>{valor && valor.buzoSimple11 === "" || componentPreview === true ? 11 : valor && valor.buzoSimple11}</p></span>
                    <span id="buzoSimple12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("buzoSimple12")}><p className={clases && clases.buzoSimple12 && !componentPreview ? `${clases.buzoSimple12.color} ${clases.buzoSimple12.fuente} ${clases.buzoSimple12.clase}` : "numeros"}>{valor && valor.buzoSimple12 === "" || componentPreview === true ? 12 : valor && valor.buzoSimple12}</p></span>
                    <span id="buzoSimple13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} onClick={() => handleOption("buzoSimple13")}><p className={clases && clases.buzoSimple13 && !componentPreview ? `${clases.buzoSimple13.color} ${clases.buzoSimple13.fuente} ${clases.buzoSimple13.clase}` : "numeros"}>{valor && valor.buzoSimple13 === "" || componentPreview === true ? 13 : valor && valor.buzoSimple13}</p></span>

                </div>
                <div id="espaldaBuzoSimple" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="buzo" src={cinturaEspalda} id="cinturaEspaldaBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={espaldaInferior} id="espaldaInferiorBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={espaldaSuperior} id="espaldaSuperiorBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={guardaMangasEspalda} id="espaldaGuardaMangasBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaMangas}`} />
                    <img alt="buzo" src={punosEspalda} id="espaldaPunosBuzoSimple" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={dorso} className="asd contorno" />
                    <img alt="buzo" src={contorno} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaBuzoSimple")} />
                    {/* APLIQUES */}
                    <span id="buzoSimple6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "probando1 displayFixed"} onClick={() => handleOption("buzoSimple6")}><p className={clases && clases.buzoSimple6 && !componentPreview ? `${clases.buzoSimple6.color} ${clases.buzoSimple6.fuente} ${clases.buzoSimple6.clase}` : "numeros"}>{valor && valor.buzoSimple6 === "" || componentPreview === true ? 6 : valor && valor.buzoSimple6}</p></span>
                    {/* BORDADOS */}
                    <span id="buzoSimple14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando1 displayFixed"} onClick={() => handleOption("buzoSimple14")}><p className={clases && clases.buzoSimple14 && !componentPreview ? `${clases.buzoSimple14.color} ${clases.buzoSimple14.fuente} ${clases.buzoSimple14.clase}` : "numeros"}>{valor && valor.buzoSimple14 === "" || componentPreview === true ? 14 : valor && valor.buzoSimple14}</p></span>
                    <span id="buzoSimple15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando1 displayFixed"} onClick={() => handleOption("buzoSimple15")}><p className={clases && clases.buzoSimple15 && !componentPreview ? `${clases.buzoSimple15.color} ${clases.buzoSimple15.fuente} ${clases.buzoSimple15.clase}` : "numeros"}>{valor && valor.buzoSimple15 === "" || componentPreview === true ? 15 : valor && valor.buzoSimple15}</p></span>

                </div>

            </section>
            <div id="modalbuzo_simple" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} /></div>
        </>
    )
}