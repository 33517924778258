import React, { useState, useEffect } from 'react'
import "./home.css"
/* NAVBAR */
import email from "../../imgs/navBar/email-icon.svg"
import wsp from "../../imgs/navBar/wsp-icon.svg"
import logo from "../../imgs/navBar/logo.svg"
import harvey from "../../imgs/navBar/harvey.svg"
import manoSlice from "../../imgs/scrollX/manoSlice.svg"
/* MODELOS BUZOS */
import buzo from "../../imgs/principalSelecciones/buzo.svg"
import buzo_doble from "../../imgs/principalSelecciones/buzoDoble.svg"
import buzo_simple from "../../imgs/principalSelecciones/buzoSimple.svg"
import buzo_vDoble from "../../imgs/principalSelecciones/buzoVDoble.svg"
import buzo_v from "../../imgs/principalSelecciones/buzoV.svg"
/* MODELOS CAMPERAS */
import campera from "../../imgs/principalSelecciones/campera.svg"
import campera_doble from "../../imgs/principalSelecciones/camperaDoble.svg"
import campera_vDoble from "../../imgs/principalSelecciones/camperaVDoble.svg"
import campera_v from "../../imgs/principalSelecciones/camperaV.svg"
import campera_simple from "../../imgs/principalSelecciones/camperaSimple.svg"
/* FOOTER */
import disenaTuPrenda from "../../imgs/footer/disenaTuPrenda.svg"
import harveyFooter from "../../imgs/footer/harvey.svg"
import logoFooter from "../../imgs/footer/logo.svg"
import facebook from "../../imgs/footer/seguinosFacebook.svg"
import instagram from "../../imgs/footer/seguinosInstagram.svg"
/* COMPONENTES BUZO*/
import Buzo from "../buzo/buzo"
import BuzoSimple from "../buso_simple/buso_simple"
import BuzoDoble from "../buso_doble/buso_doble"
import BuzoV from "../buso_v/buso_v"
import BuzoVDoble from "../buso_vDoble/buso_vDoble"
/* COMPONENTES CAMPERA */
import Campera from "../campera/campera"
import CamperaSimple from "../campera_simple/campera_simple"
import CamperaDoble from "../campera_doble/campera_doble"
import CamperaV from "../campera_v/campera_v"
import CamperaVDoble from "../campera_vDoble/campera_vDoble"
/* COMPONENTES */
import SelectorColores from "../selectorColores/selectorColores"
import modalOnlyMobile from "../../imgs/modalOnlineMobile.svg"

export default function Home() {

    const [component, setComponent] = useState("") /* Estado del componente que se renderiza en home */
    const [aux, setAux] = useState("") /* Auxilar del estado del componente que se renderiza */
    const [auxModels, setAuxModels] = useState("") /* Auxiliar del scrollX que se renderiza */
    const [models, setModels] = useState("") /*ScrollX que se renderiza  */

    /* Guardo el valor del modelo a mostrar */
    function handleSelectionModel(id) {
        setAux(component)
        setComponent(id)
    }
    /* Muestro el scrollX de los disferentes modelos */
    function handleModels(id) {
        setAuxModels(models)
        setModels(id)
    }
    /* UseEffect que controla que scrollX deberia mostrarse */
    useEffect(() => {
        auxModels.length && document.getElementById(auxModels).classList.toggle("disable")
        models.length && document.getElementById(models).classList.toggle("disable")
    }, [models, auxModels])

    /* UseEffect que controla que modelo se renderiza (REFACTORIZAR) */
    useEffect(() => {
        aux.length && document.getElementById(aux).classList.toggle("disable")
        component.length && document.getElementById(component).classList.toggle("disable")
    }, [component, aux])

    const [optionVisibility, SetoptionVisibility] = useState({ component: "", status: false })
    /* Muestro los apliques */
    function visibilityApliques() {
        SetoptionVisibility({ component: component, status: true, opcion: "aplique" })
    }
    /* Muestro los bordados */
    function visibilityBordados() {
        SetoptionVisibility({ component: component, status: true, opcion: "bordado" })
    }

    const [diseño, setDiseño] = useState({})
    const [clases, SetClases] = useState({})
    const [ayuda, SetAyuda] = useState(0)

    function handleColor(color) {
        color && setDiseño((preDiseño) => {
            return {
                ...preDiseño,
                color: "color" + color.slice(1)
            }
        })
    }
    /* Auxiliares colores Buzos */
    const [auxiliarBuzo, SetAuxiliarBuzo] = useState({})
    const [auxiliarBuzoSimple, SetAuxiliarBuzoSimple] = useState({})
    const [auxiliarBuzoDoble, SetAuxiliarBuzoDoble] = useState({})
    const [auxiliarBuzoV, SetAuxiliarBuzoV] = useState({})
    const [auxiliarBuzoVDoble, SetAuxiliarBuzoVDoble] = useState({})
    /* Auxiliares colores Camperas */
    const [auxiliarCampera, SetAuxiliarCampera] = useState({})
    const [auxiliarCamperaSimple, SetAuxiliarCamperaSimple] = useState({})
    const [auxiliarCamperaDoble, SetAuxiliarCamperaDoble] = useState({})
    const [auxiliarCamperaV, SetAuxiliarCamperaV] = useState({})
    const [auxiliarCamperaVDoble, SetAuxiliarCamperaVDoble] = useState({})

    /* Estados apliques de los componentes */
    const [valorBuzo, setValorBuzo] = useState(
        {
            buzo1: "",
            buzo2: "",
            buzo3: "",
            buzo4: "",
            buzo5: "",
            buzo6: "",
            buzo7: "",
            buzo8: "",
            buzo9: "",
            buzo10: "",
            buzo11: "",
            buzo12: "",
            buzo13: "",
            buzo14: "",
            buzo15: "",
        }
    )

    const [valorBuzoSimple, setValorBuzoSimple] = useState(
        {
            buzoSimple1: "",
            buzoSimple2: "",
            buzoSimple3: "",
            buzoSimple4: "",
            buzoSimple5: "",
            buzoSimple6: "",
            buzoSimple7: "",
            buzoSimple8: "",
            buzoSimple9: "",
            buzoSimple10: "",
            buzoSimple11: "",
            buzoSimple12: "",
            buzoSimple13: "",
            buzoSimple14: "",
            buzoSimple15: "",
        }
    )

    const [valorBuzoDoble, setValorBuzoDoble] = useState(
        {
            buzoDoble1: "",
            buzoDoble2: "",
            buzoDoble3: "",
            buzoDoble4: "",
            buzoDoble5: "",
            buzoDoble6: "",
            buzoDoble7: "",
            buzoDoble8: "",
            buzoDoble9: "",
            buzoDoble10: "",
            buzoDoble11: "",
            buzoDoble12: "",
        }
    )

    const [valorBuzoV, setValorBuzoV] = useState(
        {
            buzo_v1: "",
            buzo_v2: "",
            buzo_v3: "",
            buzo_v4: "",
            buzo_v5: "",
            buzo_v6: "",
            buzo_v7: "",
            buzo_v8: "",
            buzo_v9: "",
            buzo_v10: "",
            buzo_v11: "",
            buzo_v12: "",
            buzo_v13: "",
            buzo_v14: "",
        }
    )

    const [valorBuzoVDoble, setValorBuzoVDoble] = useState(
        {
            buzo_vDoble1: "",
            buzo_vDoble2: "",
            buzo_vDoble3: "",
            buzo_vDoble4: "",
            buzo_vDoble5: "",
            buzo_vDoble7: "",
            buzo_vDoble8: "",
            buzo_vDoble9: "",
            buzo_vDoble10: "",
            buzo_vDoble11: "",
            buzo_vDoble12: "",
        }
    )

    const [valorCampera, setValorCampera] = useState(
        {
            campera1: "",
            campera2: "",
            campera3: "",
            campera4: "",
            campera5: "",
            campera6: "",
            campera7: "",
            campera8: "",
            campera9: "",
            campera10: "",
            campera11: "",
            campera12: "",
            campera13: "",
            campera14: "",
            campera15: "",
        }
    )

    const [valorCamperaSimple, setValorCamperaSimple] = useState(
        {
            camperaSimple1: "",
            camperaSimple2: "",
            camperaSimple3: "",
            camperaSimple4: "",
            camperaSimple5: "",
            camperaSimple6: "",
            camperaSimple7: "",
            camperaSimple8: "",
            camperaSimple9: "",
            camperaSimple10: "",
            camperaSimple11: "",
            camperaSimple12: "",
            camperaSimple13: "",
            camperaSimple14: "",
            camperaSimple15: ""
        }
    )

    const [valorCamperaDoble, setValorCamperaDoble] = useState(
        {
            camperaDoble1: "",
            camperaDoble2: "",
            camperaDoble3: "",
            camperaDoble4: "",
            camperaDoble5: "",
            camperaDoble6: "",
            camperaDoble7: "",
            camperaDoble8: "",
            camperaDoble9: "",
            camperaDoble10: "",
            camperaDoble11: "",
            camperaDoble12: "",
            camperaDoble13: "",
            camperaDoble14: "",
            camperaDoble15: ""
        }
    )

    const [valorCamperaV, setValorCamperaV] = useState(
        {
            camperaV1: "",
            camperaV2: "",
            camperaV3: "",
            camperaV4: "",
            camperaV5: "",
            camperaV6: "",
            camperaV7: "",
            camperaV8: "",
            camperaV9: "",
            camperaV10: "",
            camperaV11: "",
            camperaV12: "",
            camperaV13: "",
            camperaV14: "",
            camperaV15: ""
        }
    )

    const [valorCamperaVDoble, setValorCamperaVDoble] = useState(
        {
            camperaVDoble1: "",
            camperaVDoble2: "",
            camperaVDoble3: "",
            camperaVDoble4: "",
            camperaVDoble5: "",
            camperaVDoble7: "",
            camperaVDoble8: "",
            camperaVDoble9: "",
            camperaVDoble10: "",
            camperaVDoble11: "",
            camperaVDoble12: "",
            camperaVDoble13: "",
            camperaVDoble14: "",
            camperaVDoble15: ""
        }
    )

    const [auxiliaresModalPreview, setAuxiliaresModalPreview] = useState() /* Auxiliar de componentes para mostrar en preview */
    useEffect(() => {
        setAuxiliaresModalPreview({})
    }, [component])
    const [valoresModalPreview, setValoresModalPreview] = useState() /* Auxiliares de valores apliques para mostrar en preview */
    /* UseEffect que controla el llenado de los auxiliares de los componentes para el modal preview */
    useEffect(() => {
        if (component === "buzo") {
            setAuxiliaresModalPreview(auxiliarBuzo)
            setValoresModalPreview(valorBuzo)
        } else if (component === "buzo_simple") {
            setAuxiliaresModalPreview(auxiliarBuzoSimple)
            setValoresModalPreview(valorBuzoSimple)
        } else if (component === "buzo_doble") {
            setAuxiliaresModalPreview(auxiliarBuzoDoble)
            setValoresModalPreview(valorBuzoDoble)
        } else if (component === "buzo_v") {
            setAuxiliaresModalPreview(auxiliarBuzoV)
            setValoresModalPreview(valorBuzoV)
        } else if (component === "buzo_vDoble") {
            setAuxiliaresModalPreview(auxiliarBuzoVDoble)
            setValoresModalPreview(valorBuzoVDoble)
        } else if (component === "campera") {
            setAuxiliaresModalPreview(auxiliarCampera)
            setValoresModalPreview(valorCampera)
        } else if (component === "campera_simple") {
            setAuxiliaresModalPreview(auxiliarCamperaSimple)
            setValoresModalPreview(valorCamperaSimple)
        } else if (component === "campera_doble") {
            setAuxiliaresModalPreview(auxiliarCamperaDoble)
            setValoresModalPreview(valorCamperaDoble)
        } else if (component === "campera_v") {
            setAuxiliaresModalPreview(auxiliarCamperaV)
            setValoresModalPreview(valorCamperaV)
        } else if (component === "campera_vDoble") {
            setAuxiliaresModalPreview(auxiliarCamperaVDoble)
            setValoresModalPreview(valorCamperaVDoble)
        }
    }, [auxiliarBuzo, auxiliarBuzoDoble, auxiliarBuzoSimple, auxiliarBuzoV, auxiliarBuzoVDoble, auxiliarCampera, auxiliarCamperaSimple, auxiliarCamperaDoble, auxiliarCamperaV, auxiliarCamperaVDoble])
    return (
        <>
            <nav>
                <img src={logo} alt="logo" className="logo" />
                <a href="/" className="harvey"> <img src={harvey} alt="harvey" id="harvey" /></a>
                <a href="https://wa.me/542477619451" className="wsp"><img src={wsp} alt="wsp" /></a>
                <a href="" className="email"><img src={email} alt="email" /> </a>
            </nav>
            <div className="cuerpo">

                <section className="primerPaso">
                    <h1>PASO 1</h1>
                    <span className="subtitleSpan">Elegi el modelo que mas les guste.</span>
                    <div className="opciones">
                        <div className="camperaBuzo">
                            <span className="buzo" onClick={() => handleModels("scrollXBuzo")}>
                                <img src={buzo} alt="buzo" />
                                <p>BUZO</p>
                            </span>

                            <span className="campera" onClick={() => handleModels("scrollXCampera")} >
                                <img src={campera} alt="campera" />
                                <p>CAMPERA</p>
                            </span>
                        </div>
                        {/* BUZO */}
                        <div className="scrollXBuzo disable" id="scrollXBuzo" >
                            <img alt="scroll" src={manoSlice} className="manoSlice" />
                            <div>
                                <span onClick={() => handleSelectionModel("buzo")} >
                                    <img src={buzo} alt="buzo" />
                                    <p>CANGURO</p>
                                </span>
                                <span onClick={() => handleSelectionModel("buzo_simple")} >
                                    <img src={buzo_simple} alt="buzo" />
                                    <p>RECORTE SIMPLE</p>
                                </span>
                                <span onClick={() => handleSelectionModel("buzo_doble")} >
                                    <img src={buzo_doble} alt="buzo" />
                                    <p>RECORTE DOBLE</p>
                                </span>
                                <span onClick={() => handleSelectionModel("buzo_v")} >
                                    <img src={buzo_v} alt="buzo" />
                                    <p>RECORTE V</p>
                                </span>
                                <span onClick={() => handleSelectionModel("buzo_vDoble")} >
                                    <img src={buzo_vDoble} alt="buzo" />
                                    <p>RECORTE V DOBLE</p>
                                </span>
                            </div>
                        </div>
                        {/* CAMPERA */}
                        <div className="scrollXCampera disable" id="scrollXCampera">
                            <img alt="scroll" src={manoSlice} className="manoSlice" />

                            <div>
                                <span onClick={() => handleSelectionModel("campera")} >
                                    <img src={campera} alt="campera" />
                                    <p>CAMPERA</p>
                                </span>
                                <span onClick={() => handleSelectionModel("campera_simple")} >
                                    <img src={campera_simple} alt="campera" />
                                    <p>RECORTE SIMPLE</p>
                                </span>
                                <span onClick={() => handleSelectionModel("campera_doble")} >
                                    <img src={campera_doble} alt="campera" />
                                    <p>RECORTE DOBLE</p>
                                </span>
                                <span onClick={() => handleSelectionModel("campera_v")} >
                                    <img src={campera_v} alt="campera" />
                                    <p>RECORTE V</p>
                                </span>
                                <span onClick={() => handleSelectionModel("campera_vDoble")} >
                                    <img src={campera_vDoble} alt="campera" />
                                    <p>RECORTE V DOBLE</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                {/* BUZO */}
                <div id="buzo" className="disable" ><Buzo clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorBuzo} setValor={setValorBuzo} auxiliar={auxiliarBuzo} SetAuxiliar={SetAuxiliarBuzo} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="buzo_simple" className="disable" ><BuzoSimple clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorBuzoSimple} setValor={setValorBuzoSimple} auxiliar={auxiliarBuzoSimple} SetAuxiliar={SetAuxiliarBuzoSimple} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="buzo_doble" className="disable" ><BuzoDoble clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorBuzoDoble} setValor={setValorBuzoDoble} auxiliar={auxiliarBuzoDoble} SetAuxiliar={SetAuxiliarBuzoDoble} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="buzo_v" className="disable" ><BuzoV clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorBuzoV} setValor={setValorBuzoV} auxiliar={auxiliarBuzoV} SetAuxiliar={SetAuxiliarBuzoV} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="buzo_vDoble" className="disable" ><BuzoVDoble clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorBuzoVDoble} setValor={setValorBuzoVDoble} auxiliar={auxiliarBuzoVDoble} SetAuxiliar={SetAuxiliarBuzoVDoble} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                {/* CAMPERA */}
                <div id="campera" className="disable"><Campera clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorCampera} setValor={setValorCampera} auxiliar={auxiliarCampera} SetAuxiliar={SetAuxiliarCampera} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="campera_simple" className="disable"><CamperaSimple clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorCamperaSimple} setValor={setValorCamperaSimple} auxiliar={auxiliarCamperaSimple} SetAuxiliar={SetAuxiliarCamperaSimple} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="campera_doble" className="disable"><CamperaDoble clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorCamperaDoble} setValor={setValorCamperaDoble} auxiliar={auxiliarCamperaDoble} SetAuxiliar={SetAuxiliarCamperaDoble} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="campera_v" className="disable"><CamperaV clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorCamperaV} setValor={setValorCamperaV} auxiliar={auxiliarCamperaV} SetAuxiliar={SetAuxiliarCamperaV} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                <div id="campera_vDoble" className="disable"><CamperaVDoble clases={clases} ayuda={ayuda} SetAyuda={SetAyuda} SetClases={SetClases} valor={valorCamperaVDoble} setValor={setValorCamperaVDoble} auxiliar={auxiliarCamperaVDoble} SetAuxiliar={SetAuxiliarCamperaVDoble} diseño={diseño} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>

                {component.length ? <SelectorColores clases={clases} ayuda={ayuda} handleColor={handleColor} valor={valoresModalPreview} auxiliar={auxiliaresModalPreview} setDiseño={setDiseño} SetoptionVisibility={SetoptionVisibility} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /> : ""}
            </div >

            <section className="modalOnlyMobile"><div><img src={modalOnlyMobile} alt="imgModal" /></div></section>

            <footer>
                <img src={logoFooter} alt="Logo" className="logoFooter" />
                <img src={harveyFooter} alt=" Name" className="harveyFooter" />
                <a href="#harvey" className="disenaTuPrenda"><img src={disenaTuPrenda} alt="Diseña tu prenda" /></a>
                <a href="https://www.facebook.com/harveypergamino" rel="noreferrer" target="_blank" className="facebook"><img src={facebook} alt="Facebook" /></a>
                <a href="https://www.instagram.com/harveyegresados_/" rel="noreferrer" target="_blank" className="instagram"><img src={instagram} alt="Instagram" /></a>
            </footer>
        </>
    )
}
