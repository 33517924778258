import React, { useState, useEffect } from 'react';
import "../components.css"
import bolsilloDoble from "../../imgs/recorteV_doble/bolsilloV_doble.svg"
import interiorCapucha from "../../imgs/recorteV_doble/capuchaV_doble.svg"
import cinturaDoble from "../../imgs/recorteV_doble/cinturaV_doble.svg"
import dorsoDoble from "../../imgs/recorteV_doble/dorsoV_doble.svg"
import franjaDoble from "../../imgs/recorteV_doble/franjaV_doble.svg";
import contornoDoble from "../../imgs/recorteV_doble/frenteV_doble.svg"
import guardaInferiorDoble from "../../imgs/recorteV_doble/guardaInferiorV.svg"
import guardaSuperiorDoble from "../../imgs/recorteV_doble/guardaSuperiorV.svg"
import frenteInferiorDoble from "../../imgs/recorteV_doble/inferiorV_doble.svg"
import interiorDoble from "../../imgs/recorteV_doble/interiorV_doble.svg"
import mangasDoble from "../../imgs/recorteV_doble/mangasV_doble.svg"
import punosDoble from "../../imgs/recorteV_doble/punosV_doble.svg"
import frenteSuperiorDoble from "../../imgs/recorteV_doble/superiorV_doble.svg"
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/camperaVDoble/cintura.svg";
import espaldaInferior from "../../imgs/dorsos/camperaVDoble/espaldaInferior.svg";
import espaldaSuperior from "../../imgs/dorsos/camperaVDoble/espaldaSuperior.svg";
import franjaEspalda from "../../imgs/dorsos/camperaVDoble/franja.svg";
import guardaInferiorEspalda from "../../imgs/dorsos/camperaVDoble/guardaInferior.svg";
import guardaSuperiorEspalda from "../../imgs/dorsos/camperaVDoble/guardaSuperior.svg";
import mangasEspalda from "../../imgs/dorsos/camperaVDoble/mangas.svg";
import punosEspalda from "../../imgs/dorsos/camperaVDoble/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function DobleV({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaCamperaVDoble")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "probandoPreview1",
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "probandoPreview6",
                posicion7: "probandoPreview7",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview9",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview11",
                posicion12: "probandoPreview12",
                posicion13: "probandoPreview13",
                posicion14: "probandoPreview14",
                posicion15: "probandoPreview15"
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "probando4",
                posicion5: "apliqueCampera5",
                posicion6: "probando6",
                posicion7: "probando7",
                posicion8: "probando8",
                posicion9: "probando9",
                posicion10: "probando10",
                posicion11: "probando11",
                posicion12: "probando12",
                posicion13: "probando13",
                posicion14: "probando14",
                posicion15: "probando15",
            })


    }, [componentPreview])


    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "mangasDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        mangas: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "franjaDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        franja: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaInferiorDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaSuperiorDobleV") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaSuperior: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }

    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} alt="mano" className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteCamperaVDoble" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="Doble" src={frenteInferiorDoble} id="frenteInferiorDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="Doble" src={frenteSuperiorDoble} id="frenteSuperiorDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="Doble" src={bolsilloDoble} id="bolsilloDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="Doble" src={interiorCapucha} id="capuchaDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="Doble" src={cinturaDoble} id="cinturaDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="Doble" src={interiorDoble} id="interiorDobleV" className="asd white" />
                    <img alt="Doble" src={mangasDoble} id="mangasDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="Doble" src={punosDoble} id="punosDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="Doble" src={franjaDoble} id="franjaDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="Doble" src={guardaInferiorDoble} id="guardaInferiorDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="Doble" src={guardaSuperiorDoble} id="guardaSuperiorDobleV" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="Doble" src={dorsoDoble} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteCamperaVDoble")} />
                    <img alt="Doble" src={contornoDoble} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="camperaVDoble1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando2" : "probando2 displayFixed"} onClick={() => handleOption("camperaVDoble1")}><p className={clases && clases.camperaVDoble1 ? `${clases.camperaVDoble1.color} ${clases.camperaVDoble1.fuente} ${clases.camperaVDoble1.clase}` : "numeros"}>{valor && valor.camperaVDoble1 === "" ? 1 : valor && valor.camperaVDoble1}</p></span>
                    <span id="camperaVDoble2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando3" : "probando3 displayFixed"} onClick={() => handleOption("camperaVDoble2")}><p className={clases && clases.camperaVDoble2 ? `${clases.camperaVDoble2.color} ${clases.camperaVDoble2.fuente} ${clases.camperaVDoble2.clase}` : "numeros"}>{valor && valor.camperaVDoble2 === "" ? 2 : valor && valor.camperaVDoble2}</p></span>
                    <span id="camperaVDoble3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando4" : "probando4 displayFixed"} onClick={() => handleOption("camperaVDoble3")}><p className={clases && clases.camperaVDoble3 ? `${clases.camperaVDoble3.color} ${clases.camperaVDoble3.fuente} ${clases.camperaVDoble3.clase}` : "numeros"}>{valor && valor.camperaVDoble3 === "" ? 3 : valor && valor.camperaVDoble3}</p></span>
                    <span id="camperaVDoble4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando5" : "probando5 displayFixed"} onClick={() => handleOption("camperaVDoble4")}><p className={clases && clases.camperaVDoble4 ? `${clases.camperaVDoble4.color} ${clases.camperaVDoble4.fuente} ${clases.camperaVDoble4.clase}` : "numeros"}>{valor && valor.camperaVDoble4 === "" ? 4 : valor && valor.camperaVDoble4}</p></span>
                    {/* BORDADOS */}
                    <span id="camperaVDoble7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("camperaVDoble7")}><p className={clases && clases.camperaVDoble7 ? `${clases.camperaVDoble7.color} ${clases.camperaVDoble7.fuente} ${clases.camperaVDoble7.clase}` : "numeros"}>{valor && valor.camperaVDoble7 === "" ? 7 : valor && valor.camperaVDoble7}</p></span>
                    <span id="camperaVDoble8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("camperaVDoble8")}><p className={clases && clases.camperaVDoble8 ? `${clases.camperaVDoble8.color} ${clases.camperaVDoble8.fuente} ${clases.camperaVDoble8.clase}` : "numeros"}>{valor && valor.camperaVDoble8 === "" ? 8 : valor && valor.camperaVDoble8}</p></span>
                    <span id="camperaVDoble9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("camperaVDoble9")}><p className={clases && clases.camperaVDoble9 ? `${clases.camperaVDoble9.color} ${clases.camperaVDoble9.fuente} ${clases.camperaVDoble9.clase}` : "numeros"}>{valor && valor.camperaVDoble9 === "" ? 9 : valor && valor.camperaVDoble9}</p></span>
                    <span id="camperaVDoble10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("camperaVDoble10")}><p className={clases && clases.camperaVDoble10 ? `${clases.camperaVDoble10.color} ${clases.camperaVDoble10.fuente} ${clases.camperaVDoble10.clase}` : "numeros"}>{valor && valor.camperaVDoble10 === "" ? 10 : valor && valor.camperaVDoble10}</p></span>
                    <span id="camperaVDoble12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("camperaVDoble12")}><p className={clases && clases.camperaVDoble12 ? `${clases.camperaVDoble12.color} ${clases.camperaVDoble12.fuente} ${clases.camperaVDoble12.clase}` : "numeros"}>{valor && valor.camperaVDoble12 === "" ? 12 : valor && valor.camperaVDoble12}</p></span>
                    <span id="camperaVDoble13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} onClick={() => handleOption("camperaVDoble13")}><p className={clases && clases.camperaVDoble13 ? `${clases.camperaVDoble13.color} ${clases.camperaVDoble13.fuente} ${clases.camperaVDoble13.clase}` : "numeros"}>{valor && valor.camperaVDoble13 === "" ? 13 : valor && valor.camperaVDoble13}</p></span>

                </div>
                <div id="espaldaCamperaVDoble" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="Doble" src={cinturaEspalda} id="cinturaEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="Doble" src={espaldaInferior} id="espaldaInferiorCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="Doble" src={espaldaSuperior} id="espaldaSuperiorCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="Doble" src={franjaEspalda} id="franjaEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="Doble" src={guardaInferiorEspalda} id="guardaInferiorEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="Doble" src={guardaSuperiorEspalda} id="guardaSuperiorEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="Doble" src={mangasEspalda} id="mangasEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="Doble" src={punosEspalda} id="punosEspaldaCamperaVDoble" className={`asd white  ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="Doble" src={contornoDoble} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaCamperaVDoble")} />
                    <img alt="Doble" src={dorsoDoble} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="camperaVDoble5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? "probando6" : "probando6 displayFixed"} onClick={() => handleOption("camperaVDoble5")}><p className={clases && clases.camperaVDoble5 ? `${clases.camperaVDoble5.color} ${clases.camperaVDoble5.fuente} ${clases.camperaVDoble5.clase}` : "numeros"}>{valor && valor.camperaVDoble5 === "" ? 5 : valor && valor.camperaVDoble5}</p></span>
                    {/* BORDADOS */}
                    <span id="camperaVDoble14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando14 displayFixed"} onClick={() => handleOption("camperaVDoble14")}><p className={clases && clases.camperaVDoble14 ? `${clases.camperaVDoble14.color} ${clases.camperaVDoble14.fuente} ${clases.camperaVDoble14.clase}` : "numeros"}>{valor && valor.camperaVDoble14 === "" ? 14 : valor && valor.camperaVDoble14}</p></span>
                    <span id="camperaVDoble15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando15 displayFixed"} onClick={() => handleOption("camperaVDoble15")}><p className={clases && clases.camperaVDoble15 ? `${clases.camperaVDoble15.color} ${clases.camperaVDoble15.fuente} ${clases.camperaVDoble15.clase}` : "numeros"}>{valor && valor.camperaVDoble15 === "" ? 15 : valor && valor.camperaVDoble15}</p></span>

                </div>
            </section>
            <div id="modalcampera_vDoble" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>
        </>
    )
}