import React, { useState, useEffect } from 'react';
import "../components.css";
import bolsillo from "../../imgs/buso_vDoble/bolsillo.svg";
import capuchaInteriorPng from "../../imgs/buso_vDoble/capucha.svg";
import cinturaPng from "../../imgs/buso_vDoble/cintura.svg";
import interiorPng from "../../imgs/buso_vDoble/interior.svg";
import contornoBuzo from "../../imgs/buso_vDoble/contornoFrente.svg";
import punosPng from "../../imgs/buso_vDoble/punos.svg";
import frenteInferior from "../../imgs/buso_vDoble/frente.svg";
import frenteSuperior from "../../imgs/buso_vDoble/superior.svg";
import dorso from "../../imgs/buso_vDoble/contornoDorso.svg";

import franja from "../../imgs/buso_vDoble/franja.svg";
import guardaInferior from "../../imgs/buso_vDoble/guardaSuperior.svg";
import guardaSuperior from "../../imgs/buso_vDoble/guardaInferior.svg";
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/buzoVDoble/cintura.svg";
import espaldaSuperior from "../../imgs/dorsos/buzoVDoble/espaldaSuperior.svg";
import espaldaInferior from "../../imgs/dorsos/buzoVDoble/espaldaInferior.svg";
import guardaInferiorEspalda from "../../imgs/dorsos/buzoVDoble/guardaEspalda.svg";
import guardaSuperiorEspalda from "../../imgs/dorsos/buzoVDoble/guardaInferiorEspalda.svg";
import punosEspalda from "../../imgs/dorsos/buzoVDoble/punos.svg";
import franjaEspalda from "../../imgs/dorsos/buzoVDoble/franjaEspalda.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Buso_vDoble({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaBuzoVDoble")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {

        componentPreview === true ?
            SetPosicionesApliques({
                posicion2: "probandoPreview2",
                posicion3: "probandoPreview3",
                posicion4: "probandoPreview4",
                posicion5: "probandoPreview5",
                posicion6: "apliqueBuzoVDoblePreview6",
                posicion7: "probandoPreview12",
                posicion8: "probandoPreview8",
                posicion9: "probandoPreview13",
                posicion10: "probandoPreview10",
                posicion11: "probandoPreview14",
                posicion12: "probandoPreview15",

            })
            :
            SetPosicionesApliques({
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "probando4",
                posicion5: "probando5",
                posicion6: "apliqueBuzoVDoble6",
                posicion7: "probando12",
                posicion8: "probando8",
                posicion9: "probando13",
                posicion10: "probando10",
                posicion11: "probando14",
                posicion12: "probando15",

            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteSuperiorBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteSuperior: diseño.color
                    }
                })
            } else if (diseño.seccion === "frenteInferiorBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frenteInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            } else if (diseño.seccion === "franjaBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        franja: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaInferiorBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaInferior: diseño.color
                    }
                })
            } else if (diseño.seccion === "guardaSuperiorBuzoVDoble") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        guardaSuperior: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }

    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteBuzoVDoble" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="buzo" src={frenteSuperior} id="frenteSuperiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={frenteInferior} id="frenteInferiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={bolsillo} id="bolsilloBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="buzo" src={capuchaInteriorPng} id="capuchaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="buzo" src={cinturaPng} id="cinturaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={interiorPng} id="interiorBuzoVDoble" className="asd white" />
                    <img alt="buzo" src={punosPng} id="punosBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={franja} id="franjaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="buzo" src={guardaInferior} id="guardaInferiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={guardaSuperior} id="guardaSuperiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="buzo" src={dorso} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteBuzoVDoble")} />
                    <img alt="buzo" src={contornoBuzo} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzo_vDoble1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "displayFixed"} onClick={() => handleOption("buzo_vDoble1")}><p className={clases && clases.buzo_vDoble1 ? `${clases.buzo_vDoble1.color} ${clases.buzo_vDoble1.fuente} ${clases.buzo_vDoble1.clase}` : "numeros"}>{valor && valor.buzo_vDoble1 === "" ? 1 : valor && valor.buzo_vDoble1}</p></span>
                    <span id="buzo_vDoble2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "displayFixed"} onClick={() => handleOption("buzo_vDoble2")}><p className={clases && clases.buzo_vDoble2 ? `${clases.buzo_vDoble2.color} ${clases.buzo_vDoble2.fuente} ${clases.buzo_vDoble2.clase}` : "numeros"}>{valor && valor.buzo_vDoble2 === "" ? 2 : valor && valor.buzo_vDoble2}</p></span>
                    <span id="buzo_vDoble3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion4 : "displayFixed"} onClick={() => handleOption("buzo_vDoble3")}><p className={clases && clases.buzo_vDoble3 ? `${clases.buzo_vDoble3.color} ${clases.buzo_vDoble3.fuente} ${clases.buzo_vDoble3.clase}` : "numeros"}>{valor && valor.buzo_vDoble3 === "" ? 3 : valor && valor.buzo_vDoble3}</p></span>
                    <span id="buzo_vDoble4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion5 : "displayFixed"} onClick={() => handleOption("buzo_vDoble4")}><p className={clases && clases.buzo_vDoble4 ? `${clases.buzo_vDoble4.color} ${clases.buzo_vDoble4.fuente} ${clases.buzo_vDoble4.clase}` : "numeros"}>{valor && valor.buzo_vDoble4 === "" ? 4 : valor && valor.buzo_vDoble4}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo_vDoble7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("buzo_vDoble7")}><p className={clases && clases.buzo_vDoble7 ? `${clases.buzo_vDoble7.color} ${clases.buzo_vDoble7.fuente} ${clases.buzo_vDoble7.clase}` : "numeros"}>{valor && valor.buzo_vDoble7 === "" ? 7 : valor && valor.buzo_vDoble7}</p></span>
                    <span id="buzo_vDoble8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("buzo_vDoble8")}><p className={clases && clases.buzo_vDoble8 ? `${clases.buzo_vDoble8.color} ${clases.buzo_vDoble8.fuente} ${clases.buzo_vDoble8.clase}` : "numeros"}>{valor && valor.buzo_vDoble8 === "" ? 8 : valor && valor.buzo_vDoble8}</p></span>
                    <span id="buzo_vDoble9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("buzo_vDoble9")}><p className={clases && clases.buzo_vDoble9 ? `${clases.buzo_vDoble9.color} ${clases.buzo_vDoble9.fuente} ${clases.buzo_vDoble9.clase}` : "numeros"}>{valor && valor.buzo_vDoble9 === "" ? 9 : valor && valor.buzo_vDoble9}</p></span>
                    <span id="buzo_vDoble10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("buzo_vDoble10")}><p className={clases && clases.buzo_vDoble10 ? `${clases.buzo_vDoble10.color} ${clases.buzo_vDoble10.fuente} ${clases.buzo_vDoble10.clase}` : "numeros"}>{valor && valor.buzo_vDoble10 === "" ? 10 : valor && valor.buzo_vDoble10}</p></span>

                </div>
                <div id="espaldaBuzoVDoble" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="buzo" src={cinturaEspalda} id="cinturaBuzoVDobleEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="buzo" src={espaldaSuperior} id="espaldaSuperiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteSuperior}`} />
                    <img alt="buzo" src={espaldaInferior} id="espaldaInferiorBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.frenteInferior}`} />
                    <img alt="buzo" src={guardaSuperiorEspalda} id="guardaSuperiorEspaldaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaSuperior}`} />
                    <img alt="buzo" src={guardaInferiorEspalda} id="guardaInferiorEspaldaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.guardaInferior}`} />
                    <img alt="buzo" src={punosEspalda} id="punosEspaldaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="buzo" src={franjaEspalda} id="franjaEspaldaBuzoVDoble" className={`asd white ${auxiliarComponent && auxiliarComponent.franja}`} />
                    <img alt="buzo" src={contornoBuzo} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaBuzoVDoble")} />
                    <img alt="buzo" src={dorso} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="buzo_vDoble5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "apliqueBuzoVDoble6 displayFixed"} onClick={() => handleOption("buzo_vDoble5")}><p className={clases && clases.buzo_vDoble5 ? `${clases.buzo_vDoble5.color} ${clases.buzo_vDoble5.fuente} ${clases.buzo_vDoble5.clase}` : "numeros"}>{valor && valor.buzo_vDoble5 === "" ? 5 : valor && valor.buzo_vDoble5}</p></span>
                    {/* BORDADOS */}
                    <span id="buzo_vDoble11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "displayFixed"} onClick={() => handleOption("buzo_vDoble11")}><p className={clases && clases.buzo_vDoble11 ? `${clases.buzo_vDoble11.color} ${clases.buzo_vDoble11.fuente} ${clases.buzo_vDoble11.clase}` : "numeros"}>{valor && valor.buzo_vDoble11 === "" ? 11 : valor && valor.buzo_vDoble11}</p></span>
                    <span id="buzo_vDoble12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("buzo_vDoble12")}><p className={clases && clases.buzo_vDoble12 ? `${clases.buzo_vDoble12.color} ${clases.buzo_vDoble12.fuente} ${clases.buzo_vDoble12.clase}` : "numeros"}>{valor && valor.buzo_vDoble12 === "" ? 12 : valor && valor.buzo_vDoble12}</p></span>
                </div>
            </section>
            <div id="modalbuzo_vDoble" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>

        </>
    )
}