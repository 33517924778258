import React, { useEffect, useState } from 'react'
import "./modalPreview.css"
/* COMPONENTES BUZO */
import Buzo from "../buzo/buzo"
import BuzoSimple from "../buso_simple/buso_simple"
import BuzoDoble from "../buso_doble/buso_doble"
import BuzoV from "../buso_v/buso_v"
import BuzoVDoble from "../buso_vDoble/buso_vDoble"
/* COMPONENTES CAMPERA */
import Campera from "../campera/campera"
import CamperaSimple from "../campera_simple/campera_simple"
import CamperaDoble from "../campera_doble/campera_doble"
import CamperaV from "../campera_v/campera_v"
import CamperaVDoble from "../campera_vDoble/campera_vDoble"

import wsp from "../../imgs/navBar/wsp-icon.svg"

import close from "../../imgs/ei_close.svg";

export default function ModalPreview({ clases, ayuda, valor, auxiliar, componentPreview, openModalPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [keys, setkeys] = useState([])
    const [values, setvalues] = useState([])
    useEffect(() => {
        auxiliar && setkeys(Object.keys(auxiliar))
        auxiliar && setvalues(Object.values(auxiliar))
    }, [auxiliar])

    useEffect(() => {
        valor && console.log(Object.keys(valor))
    }, [valor])
    return (
        <>
            <div className="modalPreview">
                <div className="modalAux">
                    <section className="instrucciones">
                        <div>
                            <h1>Ultimo Paso!</h1>
                            <ul>
                                <li>Verifica tu diseño</li>
                                <li>Comproba que los valores sean correctos</li>
                                <li>Saca los screenshots necesarios para que se vean todos los valores y envianoslos por wsp, inmediatamente nos pondremos en contacto con vos</li>
                            </ul>
                        </div>
                        <span className="closePreview" onClick={() => openModalPreview()}><img src={close} alt="close" /></span>
                    </section>

                    <div className={component === "buzo" ? "" : "displayPreview"} ><Buzo clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "buzo_simple" ? "" : "displayPreview"} ><BuzoSimple clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "buzo_doble" ? "" : "displayPreview"} ><BuzoDoble clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "buzo_v" ? "" : "displayPreview"} ><BuzoV clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "buzo_vDoble" ? "" : "displayPreview"} ><BuzoVDoble clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "campera" ? "" : "displayPreview"} ><Campera clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "campera_simple" ? "" : "displayPreview"} ><CamperaSimple clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "campera_doble" ? "" : "displayPreview"} ><CamperaDoble clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "campera_v" ? "" : "displayPreview"} ><CamperaV clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>
                    <div className={component === "campera_vDoble" ? "" : "displayPreview"} ><CamperaVDoble clases={clases} ayuda={ayuda} valor={valor} auxiliar={auxiliar} componentPreview={componentPreview} component={component} optionVisibility={optionVisibility} visibilityApliques={visibilityApliques} visibilityBordados={visibilityBordados} /></div>


                    <section className="keysValues">
                        <ul className="ulSeccionesPreview">
                            {keys.map((e) => {
                                if (keys.length && values.length && auxiliar[e]) {
                                    return (
                                        <li key={e} ><p>{e.charAt(0).toUpperCase() + e.slice(1)}: #{auxiliar[e].slice(5)}</p> <span className="circuloPreview" style={{ "backgroundColor": `#${auxiliar[e].slice(5)}` }}></span></li>
                                    )
                                }
                            })}
                        </ul>



                    </section>
                    <ul className="previewAPliBord">
                        {valor && Object.keys(valor).map((value) => {
                            if (clases[value] && clases[value].fuente && clases[value].color) {
                                return (
                                    <li>{value}: {valor[value]}, {clases[value].fuente}, #{clases[value].color.slice(5)} <div className="circuloPreview" style={{ "backgroundColor": `#${clases[value].color.slice(5)}` }}></div></li>
                                )
                            }
                        })}


                    </ul>

                    <button><a href="https://wa.me/542477619451" >WhatsApp <img src={wsp} alt="wsp" /></a></button>
                </div>
            </div>
        </>
    )
}