import React, { useState, useEffect } from 'react';
import "../components.css"
import bolsilloCampera from "../../imgs/campera/bolsilloCampera.svg"
import contornoCampera from "../../imgs/campera/contornoCampera.svg"
import dorsoCampera from "../../imgs/campera/dorsoCampera.svg"
import frenteCampera from "../../imgs/campera/frenteCampera.svg"
import guardaInferiorCampera from "../../imgs/campera/guardaInferiorCampera.svg"
import interiorCampera from "../../imgs/campera/interiorCampera.svg"
import interiorCapucha from "../../imgs/campera/interiorCapucha.svg"
import mangasCampera from "../../imgs/campera/mangasCampera.svg"
import punosCampera from "../../imgs/campera/punosCampera.svg"
/* ESPALDA */
import cinturaEspalda from "../../imgs/dorsos/campera/cintura.svg";
import espalda from "../../imgs/dorsos/campera/espalda.svg";
import mangasEspalda from "../../imgs/dorsos/campera/mangas.svg";
import punosEspalda from "../../imgs/dorsos/campera/punos.svg";

import Modal from "../modal/modal"
import manoTurn from "../../imgs/scrollX/manoTurn.svg"

export default function Campera({ ayuda, clases, SetClases, SetAyuda, valor, setValor, auxiliar, SetAuxiliar, diseño, componentPreview, component, optionVisibility, visibilityApliques, visibilityBordados }) {
    const [prenda, setPrenda] = useState("espaldaCampera")
    const [aux, setAux] = useState("")
    const [option, setOption] = useState("")
    const [auxiliarComponent, SetAuxiliarComponent] = useState({})

    useEffect(() => {
        SetAuxiliarComponent(auxiliar)
    }, [auxiliar])

    useEffect(() => {
        if (valor && option.length && valor[option] === "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "numeros", fuente: "", color: "" }
                }
            })
        } else if (valor && option.length && valor[option] !== "") {
            SetClases((preValor) => {
                return {
                    ...preValor, [option]: { ...preValor[option], clase: "fix" }
                }
            })
        }
    }, [valor, option, ayuda])

    const [posicionesApliques, SetPosicionesApliques] = useState({})
    useEffect(() => {
        componentPreview === true ?
            SetPosicionesApliques({
                posicion1: "previewCampera1",
                posicion2: "previewCampera2",
                posicion3: "previewCampera3",
                posicion4: "previewCampera4",
                posicion5: "previewCampera5",
                posicion6: "previewCampera6",
                posicion7: "previewCampera7",
                posicion8: "previewCampera8",
                posicion9: "previewCampera9",
                posicion10: "previewCampera10",
                posicion11: "previewCampera11",
                posicion12: "previewCampera12",
                posicion13: "previewCampera13",
                posicion14: "previewCampera14",
                posicion15: "previewCampera15"
            })
            :
            SetPosicionesApliques({
                posicion1: "probando1",
                posicion2: "probando2",
                posicion3: "probando3",
                posicion4: "apliqueCampera4",
                posicion5: "apliqueCampera5",
                posicion6: "probando6",
                posicion7: "probandoCampera7",
                posicion8: "probandoCampera8",
                posicion9: "probandoCampera9",
                posicion10: "probandoCampera10",
                posicion11: "probandoCampera11",
                posicion12: "probandoCampera12",
                posicion13: "probandoCampera13",
                posicion14: "probandoCampera14",
                posicion15: "probandoCampera15",
            })


    }, [componentPreview])

    useEffect(() => {
        if (diseño && diseño.seccion && diseño.color) {
            if (diseño.seccion === "frenteCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        frente: diseño.color
                    }
                })
            } else if (diseño.seccion === "bolsilloCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        bolsillo: diseño.color
                    }
                })
            } else if (diseño.seccion === "capuchaCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        capucha: diseño.color
                    }
                })
            } else if (diseño.seccion === "cinturaCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        cintura: diseño.color
                    }
                })
            } else if (diseño.seccion === "mangasCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        mangas: diseño.color
                    }
                })
            } else if (diseño.seccion === "punosCampera") {
                SetAuxiliar((preauxiliar) => {
                    return {
                        ...preauxiliar,
                        punos: diseño.color
                    }
                })
            }
        }
    }, [diseño])

    useEffect(() => {
        if (aux.length > 0) {
            document.getElementById(aux).classList.remove("display")
        }
        setAux(prenda)
        document.getElementById(prenda).classList.add("display")
    }, [prenda])

    function handleBuzo(position) {
        setPrenda(position)
    }
    function handleOption(id) {
        document.getElementById(`modal${component}`).classList.remove("open")
        setOption(id)
    }

    return (
        <>
            <section className={componentPreview === true ? "partesBlancoPreview" : "partesBlanco"}>
                <div className={componentPreview === true ? "display" : "pasoDos"}>
                    <h1>PASO 2</h1>
                    <span className="subtitleSpan">Elegi el color</span>
                </div>
                <div className={componentPreview === true ? "display" : "apliBordOpciones"}>
                    <span onClick={() => visibilityApliques()}>Apliques</span>
                    <span onClick={() => visibilityBordados()}>Bordados</span>
                </div>
                <img src={manoTurn} className={componentPreview === true ? "display" : "manoTurn"} />

                <div id="frenteCamperaDiv" className={componentPreview === true ? "frentePreview" : "frente"}>
                    <img alt="campera" src={frenteCampera} id="frenteCampera" className={`asd white dsa ${auxiliarComponent && auxiliarComponent.frente}`} />
                    <img alt="campera" src={bolsilloCampera} id="bolsilloCampera" className={`asd white ${auxiliarComponent && auxiliarComponent.bolsillo}`} />
                    <img alt="campera" src={interiorCapucha} id="capuchaCampera" className={`asd white ${auxiliarComponent && auxiliarComponent.capucha}`} />
                    <img alt="campera" src={guardaInferiorCampera} id="cinturaCampera" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="campera" src={interiorCampera} id="interiorCampera" className="asd white" />
                    <img alt="campera" src={mangasCampera} id="mangasCampera" className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="campera" src={punosCampera} id="punosCampera" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="campera" src={dorsoCampera} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("frenteCamperaDiv")} />
                    <img alt="campera" src={contornoCampera} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="campera1" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion1 : "probando1 displayFixed"} onClick={() => handleOption("campera1")}><p className={clases && clases.campera1 ? `${clases.campera1.color} ${clases.campera1.fuente} ${clases.campera1.clase}` : "numeros"}>{valor && valor.campera1 === "" ? 1 : valor && valor.campera1}</p></span>
                    <span id="campera2" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion2 : "probando1 displayFixed"} onClick={() => handleOption("campera2")}><p className={clases && clases.campera2 ? `${clases.campera2.color} ${clases.campera2.fuente} ${clases.campera2.clase}` : "numeros"}>{valor && valor.campera2 === "" ? 2 : valor && valor.campera2}</p></span>
                    <span id="campera3" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion3 : "probando1 displayFixed"} onClick={() => handleOption("campera3")}><p className={clases && clases.campera3 ? `${clases.campera3.color} ${clases.campera3.fuente} ${clases.campera3.clase}` : "numeros"}>{valor && valor.campera3 === "" ? 3 : valor && valor.campera3}</p></span>
                    <span id="campera4" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion4 : "apliqueCampera4 displayFixed"} onClick={() => handleOption("campera4")}><p className={clases && clases.campera4 ? `${clases.campera4.color} ${clases.campera4.fuente} ${clases.campera4.clase}` : "numeros"}>{valor && valor.campera4 === "" ? 4 : valor && valor.campera4}</p></span>
                    <span id="campera5" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion5 : "apliqueCampera5 displayFixed"} onClick={() => handleOption("campera5")}><p className={clases && clases.campera5 ? `${clases.campera5.color} ${clases.campera5.fuente} ${clases.campera5.clase}` : "numeros"}>{valor && valor.campera5 === "" ? 5 : valor && valor.campera5}</p></span>
                    {/* BORDADOS */}
                    <span id="campera7" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion7 : "displayFixed"} onClick={() => handleOption("campera7")}><p className={clases && clases.campera7 ? `${clases.campera7.color} ${clases.campera7.fuente} ${clases.campera7.clase}` : "numeros"}>{valor && valor.campera7 === "" ? 7 : valor && valor.campera7}</p></span>
                    <span id="campera8" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion8 : "displayFixed"} onClick={() => handleOption("campera8")}><p className={clases && clases.campera8 ? `${clases.campera8.color} ${clases.campera8.fuente} ${clases.campera8.clase}` : "numeros"}>{valor && valor.campera8 === "" ? 8 : valor && valor.campera8}</p></span>
                    <span id="campera9" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion9 : "displayFixed"} onClick={() => handleOption("campera9")}><p className={clases && clases.campera9 ? `${clases.campera9.color} ${clases.campera9.fuente} ${clases.campera9.clase}` : "numeros"}>{valor && valor.campera9 === "" ? 9 : valor && valor.campera9}</p></span>
                    <span id="campera10" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion10 : "displayFixed"} onClick={() => handleOption("campera10")}><p className={clases && clases.campera10 ? `${clases.campera10.color} ${clases.campera10.fuente} ${clases.campera10.clase}` : "numeros"}>{valor && valor.campera10 === "" ? 10 : valor && valor.campera10}</p></span>
                    <span id="campera11" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion11 : "displayFixed"} onClick={() => handleOption("campera11")}><p className={clases && clases.campera11 ? `${clases.campera11.color} ${clases.campera11.fuente} ${clases.campera11.clase}` : "numeros"}>{valor && valor.campera11 === "" ? 11 : valor && valor.campera11}</p></span>
                    <span id="campera12" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion12 : "displayFixed"} onClick={() => handleOption("campera12")}><p className={clases && clases.campera12 ? `${clases.campera12.color} ${clases.campera12.fuente} ${clases.campera12.clase}` : "numeros"}>{valor && valor.campera12 === "" ? 12 : valor && valor.campera12}</p></span>
                    <span id="campera13" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion13 : "displayFixed"} onClick={() => handleOption("campera13")}><p className={clases && clases.campera13 ? `${clases.campera13.color} ${clases.campera13.fuente} ${clases.campera13.clase}` : "numeros"}>{valor && valor.campera13 === "" ? 13 : valor && valor.campera13}</p></span>

                </div>
                <div id="espaldaCampera" className={componentPreview === true ? "espaldaPreview" : "espalda"}>
                    <img alt="campera" src={cinturaEspalda} id="cinturaCamperaEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.cintura}`} />
                    <img alt="campera" src={espalda} id="espaldaCamperaImg" className={`asd white ${auxiliarComponent && auxiliarComponent.frente}`} />
                    <img alt="campera" src={mangasEspalda} id="mangasCamperaEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.mangas}`} />
                    <img alt="campera" src={punosEspalda} id="punosCamperaEspalda" className={`asd white ${auxiliarComponent && auxiliarComponent.punos}`} />
                    <img alt="campera" src={contornoCampera} className={componentPreview === true ? "oculto" : "dorso"} onClick={() => handleBuzo("espaldaCampera")} />
                    <img alt="campera" src={dorsoCampera} className="asd contorno" />
                    {/* APLIQUES */}
                    <span id="campera6" className={optionVisibility.component === component && optionVisibility.opcion === "aplique" || componentPreview == true ? posicionesApliques.posicion6 : "probando6 displayFixed"} onClick={() => handleOption("campera6")}><p className={clases && clases.campera6 ? `${clases.campera6.color} ${clases.campera6.fuente} ${clases.campera6.clase}` : "numeros"}>{valor && valor.campera6 === "" ? 6 : valor && valor.campera6}</p></span>
                    {/* BORDADOS */}
                    <span id="campera14" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion14 : "probando14 displayFixed"} onClick={() => handleOption("campera14")}><p className={clases && clases.campera14 ? `${clases.campera14.color} ${clases.campera14.fuente} ${clases.campera14.clase}` : "numeros"}>{valor && valor.campera14 === "" ? 14 : valor && valor.campera14}</p></span>
                    <span id="campera15" className={optionVisibility.component === component && optionVisibility.opcion === "bordado" || componentPreview == true ? posicionesApliques.posicion15 : "probando15 displayFixed"} onClick={() => handleOption("campera15")}><p className={clases && clases.campera15 ? `${clases.campera15.color} ${clases.campera15.fuente} ${clases.campera15.clase}` : "numeros"}>{valor && valor.campera15 === "" ? 15 : valor && valor.campera15}</p></span>

                </div>
            </section>
            <div id="modalcampera" className="open"><Modal SetAyuda={SetAyuda} SetClases={SetClases} component={component} option={option} setValor={setValor} valor={valor} /></div>
        </>
    )
}